import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  CardList,
  Check2Circle,
  ExclamationCircleFill,
  PlusCircle,
} from "react-bootstrap-icons";
import axios from "axios";
import CalendarAgenda from "./CalendarAgenda";
import Dialog from "../../Shared/Dialog";
import CalendarEventDetails from "./CalendarEventDetails";
import padZeros, { agendaTime, dateToString } from "../../Shared/Util";
import CalendarDayTemplateSelector from "./CalendarDayTemplateSelector";
import DateHeader from "../../Shared/DateHeader";
import ReturnLink from "../../Shared/Return";
import moment from "moment";
import NoteAddButton from "../Note/Note";
import PageIntro from "../../Shared/PageInto";

const CalendarDay = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [routines, setRoutines] = useState([]);
  const [dayTemplates, setDayTemplates] = useState([]);
  const [notes, setNotes] = useState([]);
  const [students, setStudents] = useState(null);
  const [availableStudents, setAvailableStudents] = useState([]);
  const [utilizedStudents, setUtilizedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [scroll, setScroll] = useState({ ref: null, value: agendaTime() });
  const [activeEvent, setActiveEvent] = useState(null);
  const [showDayTemplateSelector, setShowDayTemplateSelector] = useState(false);
  const [formData, setFormData] = useState({
    userId: props.userId,
    date: dateToString(new Date(props.year, props.month, props.day)),
    events: [],
  });

  const addEvent = (studentId, hour) => {
    const newEvent = {
      id: null,
      fk_studentId: studentId,
      fk_subjectId: "2aedf884-b6c1-11ec-ab0f-002590aaf58e",
      date: `${padZeros(props.year, 4)}-${padZeros(props.month, 2)}-${padZeros(
        props.day,
        2
      )}`,
      start: `${padZeros(hour, 2)}:00`,
      startActual: null,
      end: `${padZeros(hour + 1, 2)}:00`,
      endActual: null,
      title: "",
      notes: "",
      pointsCompletion: null,
      points70: null,
      points80: null,
      points90: null,
      points100: null,
      completed: false,
      percentage: null,
      deactivatedDateTime: null,
    };

    setActiveEvent(newEvent);
    updateEvents({ ...formData }, [...formData.events, newEvent]);
  };

  const addUtilizedStudent = (uUtilizedStudents) => {
    const availableStudent = updateStudentData(students, [
      ...(uUtilizedStudents || utilizedStudents),
      selectedStudent,
    ]);
    setSelectedStudent(availableStudent);
  };

  const applyDayTemplate = () => {
    setShowDayTemplateSelector(true);
  };

  const dateLeft = () => {
    const date = `${padZeros(props.year, 4)}-${padZeros(
      props.month,
      2
    )}-${padZeros(props.day, 2)}`;
    const newDate = moment(date).subtract(1, "days");

    window.location = `/mycalendar/year/${newDate.format(
      "YYYY"
    )}/month/${newDate.format("MM")}/day/${newDate.format("DD")}`;
  };

  const dateRight = () => {
    const date = `${padZeros(props.year, 4)}-${padZeros(
      props.month,
      2
    )}-${padZeros(props.day, 2)}`;
    const newDate = moment(date).add(1, "days");

    window.location = `/mycalendar/year/${newDate.format(
      "YYYY"
    )}/month/${newDate.format("MM")}/day/${newDate.format("DD")}`;
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const purgeEvents = () => {
    const events = formData.events.filter((event) =>
      event.id || !event.deactivatedDateTime ? event : null
    );
    setFormData({ ...formData, events: [...events] });
    return events;
  };

  const removeEvent = (event, index) => {
    formData.events[index].deactivatedDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setFormData({ ...formData, events: [...formData.events] });
    if (event === activeEvent) setActiveEvent(null);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    setActiveEvent(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Calendar"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/calendarDayEdit",
        qs.stringify({
          userId: props.userId,
          events: JSON.stringify(purgeEvents()),
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({
            ...formData,
            events: JSON.parse(response.data.events),
          });
          showDialog("Events created/updated successfully!", "Calendar");
        }
      });
  };

  const swapUtilizedStudent = (studentOld, studentNew) => {
    const buffer = [...utilizedStudents];

    buffer.splice(utilizedStudents.indexOf(studentOld), 1);

    setSelectedStudent(studentNew);
    addUtilizedStudent(buffer);
  };

  const updateEvents = useCallback((uFormData, uEvents) => {
    const events = [...(uEvents || uFormData.events)].sort((a, b) => {
      const durationA = Math.abs(
        new Date(`2000-01-01 ${a.end}`).getTime() -
          new Date(`2000-01-01 ${a.start}`).getTime()
      );
      const durationB = Math.abs(
        new Date(`2000-01-01 ${b.end}`).getTime() -
          new Date(`2000-01-01 ${b.start}`).getTime()
      );

      const startA = new Date(`2000-01-01 ${a.start}`).getTime();
      const startB = new Date(`2000-01-01 ${b.start}`).getTime();

      if (durationA < durationB) return -1;
      else if (durationA > durationB) return 1;
      else if (startA < startB) return -1;
      else if (startA > startB) return 1;
      else return 0;
    });

    setFormData({ ...uFormData, events: [...events] });
  }, []);

  const updateEventsExt = (uEvents) => updateEvents({ ...formData }, uEvents);

  const updateStudentData = (uStudents, uUtilizedStudents) => {
    const buffer = [];
    uStudents.forEach((student) => {
      if (uUtilizedStudents.indexOf(student) < 0) buffer.push(student);
    });

    setUtilizedStudents(uUtilizedStudents);
    setAvailableStudents(buffer);

    if (buffer.length) setSelectedStudent(buffer[0]);

    return buffer.length ? buffer[0] : null;
  };

  const _getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourschoolhub.online/calendarDay/id/${
          props.userId
        }/date/${padZeros(props.year, 4)}-${padZeros(
          props.month,
          2
        )}-${padZeros(props.day, 2)}`
      )
      .then((response) => {
        const responseStudents = JSON.parse(response.data.students);
        const responseEvents = JSON.parse(response.data.events);
        const responseRoutines = JSON.parse(response.data.routines);
        const responseDayTemplates = JSON.parse(response.data.dayTemplates);
        const responseNotes = JSON.parse(response.data.notes);
        const responseStudentIds = responseStudents.map(
          (student) => student.id
        );

        setStudents(responseStudents);
        updateStudentData(responseStudents, [
          ...new Set(
            responseEvents.map(
              (event) =>
                responseStudents[responseStudentIds.indexOf(event.fk_studentId)]
            )
          ),
        ]);

        setRoutines(responseRoutines);
        setDayTemplates(responseDayTemplates);
        setNotes(responseNotes);

        updateEvents(
          {
            userId: props.userId,
            date: dateToString(new Date(props.year, props.month, props.day)),
            events: [],
          },
          responseEvents
        );
      });
  }, [props.userId, props.year, props.month, props.day, updateEvents]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData || !students) return null;

  return (
    <div className="calendar">
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <CalendarEventDetails
        show={!!activeEvent}
        events={formData.events}
        event={activeEvent}
        index={formData.events.indexOf(activeEvent)}
        updateEvents={updateEventsExt}
        onSave={submit}
        onClose={() => setActiveEvent(null)}
      />

      <CalendarDayTemplateSelector
        show={showDayTemplateSelector}
        onClose={() => setShowDayTemplateSelector(false)}
        date={`${padZeros(props.year, 4)}-${padZeros(
          props.month,
          2
        )}-${padZeros(props.day, 2)}`}
        students={students}
        utilizedStudents={utilizedStudents}
        events={formData.events}
        dayTemplates={dayTemplates}
        updateEvents={updateEventsExt}
        removeEvent={removeEvent}
        updateStudentData={updateStudentData}
      />

      <Row className="mt-3 gx-1">
        <Col md={4} xxl={4}>
          <ReturnLink href="/mycalendar" label="Calendar" />

          <DateHeader
            date={new Date(props.year, props.month - 1, props.day)}
            onDateLeft={dateLeft}
            onDateRight={dateRight}
          />
        </Col>
        <Col md={8} xxl={8} className="text-end">
          <Button
            variant="link"
            className="text-decoration-none"
            onClick={() => applyDayTemplate()}
          >
            <CardList size="1.5em" />
            &nbsp;Apply Day Template
          </Button>
          <PageIntro noGuide={props.noGuide} noMT leftCol={9} rightCol={3}>
            Hello &amp; welcome! Let's begin planning our day!
          </PageIntro>
        </Col>
      </Row>

      {!(students || []).length && (
        <Alert variant="danger" className="mt-3">
          <a href="/mystudents" className="text-decoration-none text-danger">
            <ExclamationCircleFill /> You currently have no students. Click here
            to add students.
          </a>
        </Alert>
      )}

      {!!(students || []).length && !(utilizedStudents || []).length && (
        <Alert variant="info" className="mt-3">
          <ExclamationCircleFill /> You currently haven't added any students to
          the day. To do so:
          <ol className="mt-3">
            <li>
              select a student from the dropdown below and then press "Add
              Student"
            </li>
            <li>
              click "Apply Day Template" to apply any day template you've
              already created
            </li>
          </ol>
        </Alert>
      )}

      {!!(students || []).length &&
        !!(utilizedStudents || []).length &&
        !(formData.events || []).length && (
          <Alert variant="info" className="mt-3">
            <ExclamationCircleFill /> You currently haven't added any tasks to
            the day. To do so:
            <ol className="mt-3">
              <li>click on the appropriate hour within a student's timeline</li>
              <li>
                click "Apply Routine" within a student's timeline to apply any
                routines you've already created
              </li>
              <li>
                click "Apply Day Template" to apply any day template you've
                already created
              </li>
            </ol>
          </Alert>
        )}

      <Row>
        <Col lg={2} className="pt-3">
          <NoteAddButton
            userId={props.userId}
            date={`${padZeros(props.year, 4)}-${padZeros(
              props.month,
              2
            )}-${padZeros(props.day, 2)}`}
            notes={notes}
            setNotes={setNotes}
          />
        </Col>

        <Col lg={10}>
          {utilizedStudents.map((student, index) => (
            <CalendarAgenda
              key={`studentAgenda${index}`}
              date={`${padZeros(props.year, 4)}-${padZeros(
                props.month,
                2
              )}-${padZeros(props.day, 2)}`}
              student={student}
              students={students}
              events={formData.events}
              availableStudents={availableStudents}
              utilizedStudents={utilizedStudents}
              scroll={scroll}
              onScroll={(ref) =>
                setScroll({ ref: ref, value: ref.current.scrollLeft })
              }
              addUtilizedStudent={addUtilizedStudent}
              swapUtilizedStudent={swapUtilizedStudent}
              addEvent={addEvent}
              updateEvents={updateEventsExt}
              setActiveEvent={setActiveEvent}
              removeEvent={removeEvent}
              routines={routines}
            />
          ))}
          <Row className="mt-3">
            <Col md={8}></Col>
            <Col>
              <InputGroup>
                <Form.Select
                  disabled={!availableStudents.length}
                  onChange={(e) =>
                    setSelectedStudent(students[parseInt(e.target.value)])
                  }
                >
                  {students.map((student, index) =>
                    utilizedStudents.indexOf(student) >= 0 ? null : (
                      <option key={`studentOption${index}`} value={index}>
                        {student.alias || student.login}
                      </option>
                    )
                  )}
                </Form.Select>
                <Button
                  disabled={!availableStudents.length}
                  variant="outline-secondary"
                  onClick={() => addUtilizedStudent()}
                >
                  <PlusCircle></PlusCircle>&nbsp;Add Student
                </Button>

                <Button variant="primary" onClick={() => submit()}>
                  <Check2Circle></Check2Circle>&nbsp;Save
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CalendarDay;
