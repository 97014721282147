import { useEffect, useState } from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import padZeros from "../Shared/Util";

const InputTime = (props) => {
  const propsDate = props?.time
    ? new Date(`2000-01-01T${props.time}`)
    : new Date();
  const [selectedHour, setSelectedHour] = useState(
    propsDate.getHours() > 12
      ? propsDate.getHours() - 12
      : propsDate.getHours() || 12
  );
  const [selectedMinute, setSelectedMinute] = useState(propsDate.getMinutes());
  const [selectedPeriod, setSelectedPeriod] = useState(
    propsDate.getHours() >= 12 ? "PM" : "AM"
  );

  const minutes = [];
  for (var h = 0; h <= 59; h++) minutes.push(h);

  const updateHour = (hour) => {
    setSelectedHour(hour);
    if (props.onChange)
      props.onChange(
        `${padZeros(
          selectedPeriod === "PM" ? parseInt(hour) + 12 : hour,
          2
        )}:${padZeros(selectedMinute, 2)}:00`
      );
  };

  const updateMinute = (minute) => {
    setSelectedMinute(minute);
    if (props.onChange)
      props.onChange(
        `${padZeros(
          selectedPeriod === "PM" ? parseInt(selectedHour) + 12 : selectedHour,
          2
        )}:${padZeros(minute, 2)}:00`
      );
  };

  const updatePeriod = (period) => {
    setSelectedPeriod(period);
    if (props.onChange)
      props.onChange(
        `${padZeros(
          period === "PM" ? parseInt(selectedHour) + 12 : selectedHour,
          2
        )}:${padZeros(selectedMinute, 0)}:00`
      );
  };

  useEffect(() => {
    const propsDate = props?.time
      ? new Date(`2022-01-01T${props.time}`)
      : new Date();
    setSelectedHour(
      propsDate.getHours() > 12
        ? propsDate.getHours() - 12
        : propsDate.getHours() || 12
    );
    setSelectedMinute(propsDate.getMinutes());
    setSelectedPeriod(propsDate.getHours() >= 12 ? "PM" : "AM");
  }, [props?.time]);

  return (
    <div className={props.className}>
      <Card>
        {props.label && (
          <small className="text-secondary ps-2 pt-2">{props.label}</small>
        )}
        <Card.Body className="p-0">
          <InputGroup>
            <Form.Select
              className="border-0 p-0 ps-2"
              value={selectedHour}
              onChange={(e) => updateHour(e.target.value)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour, index) => (
                <option key={`hour-${index}`} value={hour}>
                  {hour}
                </option>
              ))}
            </Form.Select>
            &nbsp;<span className="mt-1">:</span>&nbsp;
            <Form.Select
              className="border-0 p-0 ps-2"
              value={selectedMinute}
              onChange={(e) => updateMinute(e.target.value)}
            >
              {minutes.map((minute, index) => (
                <option key={`day-${index}`} value={minute}>
                  {minute}
                </option>
              ))}
            </Form.Select>
            &nbsp;<span className="mt-1">:</span>&nbsp;
            <Form.Select
              className="border-0 p-0 ps-2"
              value={selectedPeriod}
              onChange={(e) => updatePeriod(e.target.value)}
            >
              {["AM", "PM"].map((period, index) => (
                <option key={`period-${index}`} value={period}>
                  {period}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Card.Body>
      </Card>
      {props.label && <Form.Text>{props.instructions}</Form.Text>}
    </div>
  );
};

export default InputTime;
