import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Dialog from "../Shared/Dialog";
import axios from "axios";

const StudentRaiseHand = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [showButton, setShowButton] = useState(true);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const raiseHand = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Requesting help...</span>
      </Spinner>,
      "Requesting Help"
    );

    setShowButton(false);

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/studentRaiseHand",
        qs.stringify({
          userId: props.userId,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        hideDialog();
        setTimeout(() => setShowButton(true), 30000);
      });
  };
  return (
    <>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>
      {showButton && (
        <Button onClick={() => raiseHand()}>
          <img alt="raise hand" src="/hand.svg" style={{ width: "1rem" }} />{" "}
          Raise your hand
        </Button>
      )}
    </>
  );
};

export default StudentRaiseHand;
