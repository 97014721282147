import { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { ClipboardPlus, PlusCircle, XCircleFill } from "react-bootstrap-icons";
import Dialog from "../../Shared/Dialog";
import TooltipOverlay from "../../TooltipOverlay";
import Confirm from "../../Shared/Confirm";
import PageIntro from "../../Shared/PageInto";

const DayTemplates = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [remove, setRemove] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    dayTemplates: [],
  });

  const duplicateDayTemplate = async () => {
    const dayTemplate = duplicate;
    setDuplicate(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Day Template"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/dayTemplateDuplicate",
        qs.stringify({
          userId: props.userId,
          dayTemplateId: dayTemplate.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({ userId: props.userId, dayTemplates: response.data });
          showDialog("Day Template duplicated successfully!", "Day Template");
        }
      });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const removeDayTemplate = async () => {
    const dayTemplate = remove;
    setRemove(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Day Template"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/dayTemplateRemove",
        qs.stringify({
          id: formData.userId,
          dayTemplateId: dayTemplate.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({ userId: props.userId, dayTemplates: response.data });
          showDialog("Day Template removed successfully!", "Day Template");
        }
      });
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/dayTemplateList/id/${props.userId}`)
      .then((response) =>
        setFormData({ userId: props.userId, dayTemplates: response.data })
      );
  }, [props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData) return false;

  return (
    <Form>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={!!duplicate}
        header="Duplicate Day Template"
        message="Are you certain you want to duplicate this day template?"
        confirmText="Yes, Duplicate Day Template"
        onClose={() => setDuplicate(null)}
        onCancel={() => setDuplicate(null)}
        onConfirm={() => duplicateDayTemplate()}
      />

      <Confirm
        show={!!remove}
        header="Remove Day Template"
        message="Are you certain you want to remove this day template?"
        confirmText="Yes, Remove Day Template"
        onClose={() => setRemove(null)}
        onCancel={() => setRemove(null)}
        onConfirm={() => removeDayTemplate()}
      />

      <PageIntro>
        Often your students will repeat the same routines on certain days of the
        week. Day templates are where you can start pairing the{" "}
        <a href="/myroutines">routines</a> you've created with your students:
        effectively setting up their recurring tasks for a day. At this point,
        you don't need to worry about dates. When filling out your{" "}
        <a href="/mycalendar">calendar</a> you'll be able to apply your day
        templates to specific days.
      </PageIntro>

      {formData.dayTemplates.map((template, index) => {
        return (
          <Row
            key={`template${index}`}
            className={`mt-3 ${template.deactivatedDateTime && "d-none"}`}
          >
            <Col className="display-6 pb-3 border-bottom">
              <a
                className="text-decoration-none"
                href={`/dayTemplateEdit/id/${template.id}`}
              >
                {template.title}
              </a>
            </Col>
            <Col xs={4} md={1} className="text-end">
              <TooltipOverlay tip="Duplicate Day Template">
                <Button
                  variant="link"
                  className="text-dark text-decoration-none"
                  onClick={() => setDuplicate(template)}
                >
                  <ClipboardPlus />
                </Button>
              </TooltipOverlay>
              <TooltipOverlay tip="Remove Day Template">
                <Button
                  variant="link"
                  className="text-danger text-decoration-none"
                  onClick={() => setRemove(template)}
                >
                  <XCircleFill />
                </Button>
              </TooltipOverlay>
            </Col>
          </Row>
        );
      })}
      <div className="text-end mt-3">
        <ButtonGroup>
          <a className="btn btn-primary" href={`/dayTemplateEdit/id/`}>
            <PlusCircle />
            &nbsp;Add Day Template
          </a>
        </ButtonGroup>
      </div>
    </Form>
  );
};

export default DayTemplates;
