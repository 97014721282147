import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useCookies } from "react-cookie";
import { Col, Container, Row } from "react-bootstrap";
import { CookiesProvider } from "react-cookie";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Help from "./Help";
import LoginSignup from "./Account/LoginSignup";
import Menu from "./Menu";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import UserAgreement from "./Footer/UserAgreement";
import Students from "./Admin/Students/Students";
import Calendar from "./Admin/Calendar/Calendar";
import CalendarDay from "./Admin/Calendar/CalendarDay";
import Routines from "./Admin/Routine/Routines";
import StudentDay from "./Student/StudentDay";
import RoutineEdit from "./Admin/Routine/RoutineEdit";
import DayTemplates from "./Admin/DayTemplate/DayTemplates";
import DayTemplateEdit from "./Admin/DayTemplate/DayTemplateEdit";
import padZeros, { toBoolean } from "./Shared/Util";
import Prizes from "./Admin/Prizes/Prizes";
import PrizeEdit from "./Admin/Prizes/PrizeEdit";
import StudentPrizes from "./Student/StudentPrizes";
import Analytics from "./Admin/Analytics/Analytics";
import Home from "./Home/Home";
import PushNotification from "./PushNotification/PushNotification";
import Footer from "./Footer/Footer";

function App() {
  const [cookies] = useCookies(["user_id", "isStudent"]);
  const search = window.location.search
    .replace(/\?/, "")
    .split("&")
    .map((param) => {
      const fields = param.split("=");

      return { [fields[0]]: fields[1] };
    })
    .reduce((current, result) => ({ ...result, ...current }));

  const pathFull = window.location.pathname.split("/");
  const path = [...pathFull].splice(2);
  let param = search;
  let priorValue = null;
  path.forEach((value, index) => {
    if (index % 2) param = { ...param, [priorValue]: value };
    else priorValue = value;
  });

  const wrapper = (content, padding = true) => (
    <CookiesProvider>
      {cookies.user_id && !toBoolean(cookies.isStudent) && (
        <PushNotification userId={cookies.user_id} />
      )}
      <Menu />
      <Row>
        <Col xxl={2} />
        <Col xxl={8} className={padding && "px-2 px-lg-0"} id="colContent">
          <Container>{content}</Container>
        </Col>
        <Col xxl={2} />
      </Row>
      <Footer />
    </CookiesProvider>
  );

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            toBoolean(cookies.isStudent)
              ? wrapper(
                  <StudentDay
                    userId={cookies.user_id}
                    year={param?.year}
                    month={param?.month}
                    day={param?.day}
                  />,
                  true
                )
              : cookies.user_id
              ? wrapper(
                  <CalendarDay
                    userId={cookies.user_id}
                    year={padZeros(new Date().getFullYear(), 4)}
                    month={padZeros(new Date().getMonth() + 1, 2)}
                    day={padZeros(new Date().getDate(), 2)}
                  />
                )
              : wrapper(<Home />, false)
          }
        />
        <Route
          path="analytics"
          element={wrapper(
            <Analytics userId={cookies.user_id} id={param?.id} />
          )}
        />
        <Route
          path="dayTemplateEdit/*"
          element={wrapper(
            <DayTemplateEdit userId={cookies.user_id} id={param?.id} />
          )}
        />
        <Route
          path="help"
          element={wrapper(
            <Help noGuide={!cookies.user_id || toBoolean(cookies.isStudent)} />,
            false
          )}
        />
        <Route path="login" element={wrapper(<LoginSignup />, false)} />
        <Route
          path="mycalendar"
          element={wrapper(<Calendar userId={cookies.user_id} />, true)}
        />
        <Route
          path="mycalendar/year/*"
          element={wrapper(
            <CalendarDay
              userId={cookies.user_id}
              year={param?.year}
              month={param?.month}
              day={param?.day}
            />,
            true
          )}
        />
        <Route
          path="myday"
          element={wrapper(
            <StudentDay
              userId={cookies.user_id}
              year={param?.year}
              month={param?.month}
              day={param?.day}
            />,
            true
          )}
        />
        <Route
          path="mydaytemplates"
          element={wrapper(<DayTemplates userId={cookies.user_id} />, true)}
        />
        <Route
          path="myprizes"
          element={wrapper(<Prizes userId={cookies.user_id} />, true)}
        />
        <Route
          path="myroutines"
          element={wrapper(<Routines userId={cookies.user_id} />, true)}
        />
        <Route
          path="mystudents"
          element={wrapper(
            <Students userId={cookies.user_id}></Students>,
            true
          )}
        />
        <Route path="privacyPolicy" element={wrapper(<PrivacyPolicy />)} />
        <Route
          path="prizeEdit/*"
          element={wrapper(
            <PrizeEdit userId={cookies.user_id} id={param?.id} />
          )}
        />
        <Route
          path="prizes"
          element={wrapper(
            <StudentPrizes userId={cookies.user_id} id={param?.id} />
          )}
        />
        <Route
          path="routineEdit/*"
          element={wrapper(
            <RoutineEdit userId={cookies.user_id} id={param?.id} />
          )}
        />
        <Route path="userAgreement" element={wrapper(<UserAgreement />)} />
        <Route
          path="*"
          element={
            cookies.isStudent
              ? wrapper(
                  <StudentDay
                    userId={cookies.user_id}
                    year={param?.year}
                    month={param?.month}
                    day={param?.day}
                  />,
                  true
                )
              : wrapper({})
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
