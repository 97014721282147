import React from "react";

function Legalese(props) {
  /* return (
    <div className="text-center mb-3">
      <small className="text-center fw-bold">
        By clicking "{props.label || "Submit"}" you are agreeing to
        OurSchoolHub.online's&nbsp;
        <a href="/userAgreement" target="_blank">
          User Agreement
        </a>
        &nbsp;and&nbsp;
        <a href="/privacyPolicy" target="_blank">
          Privacy Policy
        </a>
        .{props.additionalLegalese}
      </small>
    </div>
  );*/
  return <></>;
}

export default Legalese;
