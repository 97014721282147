import { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { Check2Circle, PlusCircle, XCircleFill } from "react-bootstrap-icons";
import Dialog from "../../Shared/Dialog";
import TooltipOverlay from "../../TooltipOverlay";
import PageIntro from "../../Shared/PageInto";
import Confirm from "../../Shared/Confirm";

const Students = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [remove, setRemove] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    students: [],
  });

  const addStudent = () => {
    setFormData({
      ...formData,
      students: [
        ...formData.students,
        {
          id: null,
          login: "",
          alias: "",
          passwd: "",
          deactivatedDateTime: null,
        },
      ],
    });
  };

  const purgeStudents = () => {
    const students = formData.students.filter((student) =>
      student.id || !student.deactivatedDateTime ? student : null
    );
    setFormData({ ...formData, students: [...students] });
    return students;
  };

  const removeStudent = () => {
    const student = remove;
    setRemove(null);

    student.deactivatedDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setFormData({ ...formData, students: [...formData.students] });
  };

  const submit = async () => {
    let error = null;
    const logins = formData.students.map((student) => student.login);
    const duplicates = logins
      .map((login, index) => logins.indexOf(login) !== index)
      .includes(true);

    if (duplicates) {
      error = "2 or more of your students share the same login";
    } else {
      formData.students.forEach((student) => {
        if (
          /^[a-zA-Z0-9_]+$/g.test(student.login) === false ||
          student.login.length < 3 ||
          student.login.length > 64
        )
          error =
            "Please ensure all students have valid logins between 3 and 100 characters";
        else if (
          (student.id === null && student.passwd === null) ||
          (student.id !== null &&
            student.passwd !== null &&
            (student.passwd.length < 8 || student.passwd.length > 32))
        )
          error =
            "Please ensure all students have valid passwords between 8 and 32 characters";
      });
    }

    if (error) {
      showDialog(error);
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>,
        "Signup"
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourschoolhub.online/studentEdit",
          qs.stringify({
            userId: formData.userId,
            students: JSON.stringify(purgeStudents(formData.students)),
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data?.error) showDialog(response.data?.error);
          else {
            setFormData({
              ...formData,
              students: JSON.parse(response.data.students),
            });
            showDialog(
              <>
                Students created/updated successfully!
                <div className="mt-3 text-end">
                  <ButtonGroup>
                    <Button variant="light" onClick={hideDialog}>
                      Continue
                    </Button>
                    <a className="btn btn-primary" href="/mycalendar">
                      Fill My Calendar
                    </a>
                  </ButtonGroup>
                </div>
              </>,
              "Students"
            );
          }
        });
    }
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/studentList/id/${props.userId}`)
      .then((response) =>
        setFormData({ userId: props.userId, students: response.data })
      );
  }, [props.userId]);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData || !formData.students) return false;

  return (
    <Form>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={!!remove}
        header="Remove Student"
        message="Are you certain you want to remove this student?"
        confirmText="Yes, Remove Student"
        onClose={() => setRemove(null)}
        onCancel={() => setRemove(null)}
        onConfirm={() => removeStudent()}
      />

      <PageIntro>
        List all your students here. Students will use the provided login and
        password to login. If entered, a student's alias will be used for
        displays instead of the student's login.{" "}
        <strong>
          Please, keep in mind, if you are not updating a student's password,
          keep the field empty.
        </strong>{" "}
        After you have added your students, click <a href="/mycalendar">here</a>{" "}
        to fill your callendar, or <a href="/myroutines">here</a> to create
        routines!
      </PageIntro>

      {formData.students.map((student, index) => {
        return (
          <Row
            key={`student${index}`}
            className={`mt-3 ${student.deactivatedDateTime && "d-none"}`}
          >
            <Col>
              <FloatingLabel label="alias">
                <Form.Control
                  placeholder="alias"
                  value={student.alias}
                  onChange={(e) => {
                    student.alias = e.target.value;
                    setFormData({
                      ...formData,
                      students: [...formData.students],
                    });
                  }}
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="login">
                <Form.Control
                  placeholder="login"
                  value={student.login}
                  onChange={(e) => {
                    student.login = e.target.value;
                    setFormData({
                      ...formData,
                      students: [...formData.students],
                    });
                  }}
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="password">
                <Form.Control
                  placeholder="password"
                  onChange={(e) => {
                    student.passwd = e.target.value;
                    setFormData({
                      ...formData,
                      students: [...formData.students],
                    });
                  }}
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col xs={1} className="text-end">
              <TooltipOverlay tip="Remove Student">
                <Button
                  variant="link"
                  className="text-danger text-decoration-none"
                  onClick={() => setRemove(student)}
                >
                  <XCircleFill />
                </Button>
              </TooltipOverlay>
            </Col>
          </Row>
        );
      })}
      <div className="text-end mt-3">
        <ButtonGroup>
          <Button variant="outline-secondary" onClick={() => addStudent()}>
            <PlusCircle></PlusCircle>&nbsp;Add Student
          </Button>
          <Button variant="primary" onClick={() => submit()}>
            <Check2Circle></Check2Circle>&nbsp;Save
          </Button>
        </ButtonGroup>
      </div>
    </Form>
  );
};

export default Students;
