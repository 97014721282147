import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { useCookies } from "react-cookie";
import Guide from "../Admin/Guide/Guide";
import GuideButton from "../Admin/Guide/GuideButton";
import { toBoolean } from "./Util";

const PageIntro = (props) => {
  const [cookies, setCookie] = useCookies([
    "guideStudentsComplete",
    "guideCalendarComplete",
    "guideRoutinesComplete",
    "guideDayTemplatesComplete",
    "guidePrizesComplete",
    "guideAnalyticsComplete",
    "guideTutorialsComplete",
  ]);

  const [path] = useState(window.location.pathname.split("/"));

  const [show, setShow] = useState(
    !toBoolean(cookies.guideStudentsComplete) ||
      !toBoolean(cookies.guideCalendarComplete) ||
      !toBoolean(cookies.guideRoutinesComplete) ||
      !toBoolean(cookies.guideDayTemplatesComplete) ||
      !toBoolean(cookies.guidePrizesComplete) ||
      !toBoolean(cookies.guideAnalyticsComplete) ||
      !toBoolean(cookies.guideTutorialsComplete)
  );

  const [index, setIndex] = useState(
    !toBoolean(cookies.guideStudentsComplete)
      ? "0"
      : !toBoolean(cookies.guideCalendarComplete)
      ? "1"
      : !toBoolean(cookies.guideRoutinesComplete)
      ? "2"
      : !toBoolean(cookies.guideDayTemplatesComplete)
      ? "3"
      : !toBoolean(cookies.guidePrizesComplete)
      ? "4"
      : !toBoolean(cookies.guideAnalyticsComplete)
      ? "5"
      : !toBoolean(cookies.guideTutorialsComplete)
      ? "6"
      : null
  );

  return (
    <>
      <Guide
        show={show && !props.noGuide}
        path={path}
        index={index}
        cookies={cookies}
        setCookie={setCookie}
        setShow={setShow}
        setIndex={setIndex}
      />
      <Row
        className={`pb-3 border-bottom pageIntro ${!props.noMT && "mt-3"} ${
          props.className
        }`}
      >
        <Col md={!props.noGuide ? props.leftCol || 10 : 12}>
          <InfoCircle />
          &nbsp;
          {props.children}
          {!props.noGuide && (
            <p className="mt-3 text-secondary">
              <small>
                {" "}
                Need help? Try clicking "What should I do next?" or click&nbsp;
                <a
                  className="m3-3 text-decoration-none"
                  href="https://www.facebook.com/groups/ourschoolhub"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>{" "}
                to ask questions, share suggestions, and report issues via our
                Facebook group.
              </small>
            </p>
          )}
        </Col>
        {!props.noGuide && (
          <Col md={props.rightCol || 2}>
            <GuideButton
              setCookie={setCookie}
              setShow={setShow}
              setIndex={setIndex}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default PageIntro;
