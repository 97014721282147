import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Row,
  Spinner,
  Tabs,
} from "react-bootstrap";
import axios from "axios";
import ReactParseHTML from "react-html-parser";
import {
  Boxes,
  CashCoin,
  ClipboardPlus,
  CupStraw,
  Dribbble,
  PlusCircle,
  XCircleFill,
} from "react-bootstrap-icons";
import Dialog from "../../Shared/Dialog";
import TooltipOverlay from "../../TooltipOverlay";
import Confirm from "../../Shared/Confirm";
import { Tab } from "bootstrap";
import PageIntro from "../../Shared/PageInto";

const Prizes = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [remove, setRemove] = useState(null);
  const [claims, setClaims] = useState(null);
  const [redeem, setRedeem] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    prizes: [],
  });

  const duplicatePrize = async () => {
    const prize = duplicate;
    setDuplicate(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Prize"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/prizeDuplicate",
        qs.stringify({
          userId: props.userId,
          prizeId: prize.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({
            userId: props.userId,
            prizes: JSON.parse(response.data.prizes),
          });
          setClaims(JSON.parse(response.data.claims));
          showDialog("Prize duplicated successfully!", "Prize");
        }
      });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const redeemPrize = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Redeeming...</span>
      </Spinner>,
      "Prizes"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/prizeRedeem",
        qs.stringify({
          userId: props.userId,
          id: redeem.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        showDialog("Prize marked fulfilled!", "Prizes");
        setRedeem(null);
        setClaims(JSON.parse(response.data.claims));
      });
  };

  const removePrize = async () => {
    const prize = remove;
    setRemove(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Prize"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/prizeRemove",
        qs.stringify({
          userId: props.userId,
          prizeId: prize.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({
            userId: props.userId,
            prizes: JSON.parse(response.data.prizes),
          });
          setClaims(JSON.parse(response.data.claims));
          showDialog("Prize removed successfully!", "Prize");
        }
      });
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/prizeList/id/${props.userId}`)
      .then((response) => {
        setFormData({
          userId: props.userId,
          prizes: JSON.parse(response.data.prizes),
        });
        setClaims(JSON.parse(response.data.claims));
      });
  }, [props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData || !claims) return false;

  return (
    <>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={!!duplicate}
        header="Duplicate Prize"
        message="Are you certain you want to duplicate this prize?"
        confirmText="Yes, Duplicate Prize"
        onClose={() => setDuplicate(null)}
        onCancel={() => setDuplicate(null)}
        onConfirm={() => duplicatePrize()}
      />

      <Confirm
        show={!!remove}
        header="Remove Prize"
        message="Are you certain you want to remove this prize?"
        confirmText="Yes, Remove Prize"
        onClose={() => setRemove(null)}
        onCancel={() => setRemove(null)}
        onConfirm={() => removePrize()}
      />

      <Confirm
        show={redeem}
        header="Prize"
        message={`Are you sure you want to mark this prize fulfilled?`}
        confirmText="Yes, Mark Prize Fulfilled"
        onClose={() => setRedeem(null)}
        onCancel={() => setRedeem(null)}
        onConfirm={() => redeemPrize()}
      />

      <PageIntro>
        Everyone can benefit from incentives. Provide your students with some
        inspiration! Create prizes that your students can earn. Then, as you
        create your <a href="/myroutines">routines</a>,{" "}
        <a href="/mydaytemplates">day templates</a> and fill out your{" "}
        <a href="/mycalendar">calendar</a>, assign points to tasks. Your
        students will be able to accumulate points as they complete tasks.
        Afterwards, they can use their points to claim prizes!
      </PageIntro>

      <Tabs className="mt-3">
        <Tab eventKey="inventory" title="Inventory">
          <Row className="mt-3">
            {formData.prizes.map((prize, index) => {
              return (
                <Col key={`prize-${prize.id}`} md={4}>
                  <div className={`mb-3 p-3 border studentPrize`}>
                    <Row>
                      <Col xs={2} md={4}>
                        <Badge className={`points bg-primary text-light`}>
                          {prize.points} Pts
                        </Badge>
                        <TooltipOverlay tip="Duplicate Prize">
                          <Button
                            variant="link"
                            className="p-0 text-dark text-decoration-none"
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "-0.75rem",
                            }}
                            onClick={() => setDuplicate(prize)}
                          >
                            <ClipboardPlus />
                          </Button>
                        </TooltipOverlay>
                        <TooltipOverlay tip="Remove Prize">
                          <Button
                            variant="link"
                            className="p-0 text-danger text-decoration-none"
                            style={{
                              position: "absolute",
                              right: "-0.5rem",
                              top: "-0.75rem",
                            }}
                            onClick={() => setRemove(prize)}
                          >
                            <XCircleFill />
                          </Button>
                        </TooltipOverlay>
                        <div className="display-1 text-center align-top">
                          {prize.fk_typeId ===
                            "c25dbf5c-b9c5-11ec-ab0f-002590aaf58e" && (
                            <Dribbble />
                          )}
                          {prize.fk_typeId ===
                            "c26b754f-b9c5-11ec-ab0f-002590aaf58e" && (
                            <CashCoin />
                          )}
                          {prize.fk_typeId ===
                            "c2718e81-b9c5-11ec-ab0f-002590aaf58e" && <Boxes />}
                          {prize.fk_typeId ===
                            "c2655cc5-b9c5-11ec-ab0f-002590aaf58e" && (
                            <CupStraw />
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="title h5 pb-1 border-bottom">
                          <a
                            className="text-decoration-none"
                            href={`/prizeEdit/id/${prize.id}`}
                          >
                            {prize.title}
                          </a>
                        </div>
                        <div className="description">
                          {ReactParseHTML(prize.description)}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="text-end mt-3">
            <ButtonGroup>
              <a className="btn btn-primary" href={`/prizeEdit/id/`}>
                <PlusCircle />
                &nbsp;Add Prize
              </a>
            </ButtonGroup>
          </div>
        </Tab>
        <Tab eventKey="claims" title="Claims">
          <Row className="mt-3">
            {claims.map((prize, index) => {
              return (
                <Col key={`prize-${prize.id}`} md={4}>
                  <div className={`mb-3 p-3 border studentPrize`}>
                    <Row>
                      <Col xs={2} md={4}>
                        <Badge className={`points bg-primary text-light`}>
                          {prize.points} Pts : {prize.alias || prize.login}
                        </Badge>
                        <div className="display-1 text-center align-top">
                          {prize.fk_typeId ===
                            "c25dbf5c-b9c5-11ec-ab0f-002590aaf58e" && (
                            <Dribbble />
                          )}
                          {prize.fk_typeId ===
                            "c26b754f-b9c5-11ec-ab0f-002590aaf58e" && (
                            <CashCoin />
                          )}
                          {prize.fk_typeId ===
                            "c2718e81-b9c5-11ec-ab0f-002590aaf58e" && <Boxes />}
                          {prize.fk_typeId ===
                            "c2655cc5-b9c5-11ec-ab0f-002590aaf58e" && (
                            <CupStraw />
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="title h5 pb-1 border-bottom">
                          {prize.title}
                        </div>
                        <div className="description">
                          {ReactParseHTML(prize.description)}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="text-end">
                        <Button
                          className="bg-light text-dark"
                          onClick={() => setRedeem(prize)}
                        >
                          Mark Fulfilled
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Tab>
      </Tabs>
    </>
  );
};

export default Prizes;
