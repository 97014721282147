import { Plus } from "react-bootstrap-icons";

const CalendarHour = (props) => (
  <div
    className={`hour px-2 align-middle text-secondary ${
      !!props.addEvent && "cursorPointer"
    }`}
    style={{ left: `${props.hour * 300}px` }}
    onClick={() => {
      if (props.addEvent) props.addEvent(props.studentId, props.hour);
    }}
  >
    {props.hour > 12 ? props.hour - 12 : props.hour || 12}:00{" "}
    {props.hour >= 12 ? "PM" : "AM"} &nbsp;
    {!!props.addEvent && <Plus className="float-end pt-1"></Plus>}
  </div>
);

export default CalendarHour;
