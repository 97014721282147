import { Check2Circle, Circle } from "react-bootstrap-icons";
import { toBoolean } from "../Shared/Util";

const StudenDayAgendaList = (props) => {
  return (
    <div className="calendar mt-5">
      <div className="list">
        {props.events.map((event) =>
          event?.fk_studentId === props?.student?.id &&
          !event?.deactivatedDateTime ? (
            <div
              key={`agendaItem-${event.id}`}
              className={`studentHeader cursorPointer border-bottom pb-3 mb-3 ${
                toBoolean(event.completed) ? "text-success" : "text-dark"
              }`}
              onClick={() => props.setActiveEvent(event)}
            >
              {toBoolean(event.completed) ? <Check2Circle /> : <Circle />}
              &nbsp;
              {event.title}
              {toBoolean(event.completed) &&
                !!event.percentage &&
                event.percentage > 0 && <>&nbsp;({event.percentage}%)</>}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default StudenDayAgendaList;
