import React, { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Button, Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import Dialog from "../Shared/Dialog";
import Legalese from "../Legalese";
import padZeros from "../Shared/Util";

function Login(props) {
  const [, setCookie] = useCookies(["user_id", "isStudent"]);
  const [formData, setFormData] = useState({
    email: null,
    password: null,
  });
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    if (
      /^[a-zA-Z0-9_]+$/g.test(formData.login) === false ||
      formData.login.length < 3 ||
      formData.login.length > 64
    ) {
      showDialog("Please enter a valid login between 3 and 100 characters");
    } else if (
      formData.password === null ||
      formData.password.length < 8 ||
      formData.password.length > 32
    ) {
      showDialog("Please enter a valid password");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>,
        "Login"
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourschoolhub.online/login",
          qs.stringify({
            login: formData.login,
            password: formData.password,
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data?.error) showDialog(response.data?.error);
          else {
            setCookie("user_id", response.data.id, {
              maxAge: 90 * 24 * 60 * 60,
            });
            setCookie("isStudent", !!response.data.fk_accountId, {
              maxAge: 90 * 24 * 60 * 60,
            });
            hideDialog();
            window.location = !!response.data.fk_accountId
              ? "/myday"
              : `/mycalendar/year/${padZeros(
                  new Date().getFullYear(),
                  4
                )}/month/${padZeros(
                  new Date().getMonth() + 1,
                  2
                )}/day/${padZeros(new Date().getDate(), 2)}`;
          }
        });
    }
  };

  return (
    <Form>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      />

      <Row className="mb-3">
        <Col xs="12" md="6" className="mb-3 mb-md-0">
          <FormControl
            placeholder="login"
            name="login"
            id="login"
            onChange={(e) => {
              setFormData({ ...formData, login: e.target.value });
            }}
          />
        </Col>
        <Col xs="12" md="6">
          <FormControl
            placeholder="password"
            name="password"
            id="password"
            type="password"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="12" md="10" className="mb-3 mb-md-0">
          <Legalese label="Login" />
        </Col>
        <Col xs="12" md="2">
          <Button className="w-100 p-3" onClick={() => submit()}>
            Login
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Login;
