import ReactDOMServer from "react-dom/server";
import {
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Circle, Check2Circle } from "react-bootstrap-icons";
import { Editor } from "@tinymce/tinymce-react";
import ReactParseHTML from "react-html-parser";
import InputTime from "../../Input/InputTime";
import {
  calculateOverlaps,
  removeLineBreaks,
  toBoolean,
} from "../../Shared/Util";
import MultiSelect from "../../Shared/MultiSelect";

const RoutineEventDetails = (props) => {
  const updateEnd = (time) => {
    props.event.end = time;
    props.updateEvents();
  };

  const updateNotes = (value) => {
    props.event.notes = value;
    props.updateEvents();
  };

  const updatePoints = (field, points) => {
    props.event[field] = points;
    props.updateEvents();
  };

  const updateStart = (time) => {
    props.event.start = time;
    props.updateEvents();
  };

  const updateSubject = (values) => (props.event.subjectIds = [...values]);

  const updateTitle = (e) => {
    props.event.title = e.target.value;
    props.updateEvents();
  };

  if (!props.event) return null;

  const overlaps = calculateOverlaps(props.events, props.event);

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={props.onClose}
      onEscapeKeyDown={props.onClose}
    >
      <Modal.Header
        className={`text-end ${
          toBoolean(props.event.completed)
            ? "bg-success text-light"
            : overlaps[props.index] > 1
            ? "bg-warning text-dark"
            : "bg-standard text-dark"
        }`}
      >
        <div className="float-start display-6">
          {" "}
          {toBoolean(props.event.completed) ? (
            <Check2Circle />
          ) : (
            <Circle />
          )}{" "}
          &nbsp;
          {props.event.title}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="gx-1">
          <Col md={3} className="mb-3 mb-md-0">
            <InputTime
              label="Start Time"
              time={props.event.start}
              onChange={updateStart}
            />
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <InputTime
              label="End Time"
              time={props.event.end}
              onChange={updateEnd}
            />
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <MultiSelect
              label="Subject"
              value={props.event.subjectIds || []}
              onChange={updateSubject}
            >
              <option value="2ada5501-b6c1-11ec-ab0f-002590aaf58e">Art</option>
              <option value="fcf395a3-b9c4-11ec-ab0f-002590aaf58e">
                Faith
              </option>
              <option value="2ae1c0c3-b6c1-11ec-ab0f-002590aaf58e">
                Language
              </option>
              <option value="2ad34d9b-b6c1-11ec-ab0f-002590aaf58e">
                Language Arts
              </option>
              <option value="2ac55779-b6c1-11ec-ab0f-002590aaf58e">Math</option>
              <option value="c6a5acc6-b742-11ec-ab0f-002590aaf58e">
                Music
              </option>
              <option value="2aedf884-b6c1-11ec-ab0f-002590aaf58e">
                Other
              </option>
              <option value="ef29799c-b742-11ec-ab0f-002590aaf58e">
                Physical Education
              </option>
              <option value="2acd349f-b6c1-11ec-ab0f-002590aaf58e">
                Science
              </option>
              <option value="2ae7bbe6-b6c1-11ec-ab0f-002590aaf58e">
                Social Studies
              </option>
            </MultiSelect>
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <FloatingLabel label="Title">
              <Form.Control
                placeholder="Title"
                value={props.event.title}
                onChange={updateTitle}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mt-3 gx-1">
          <Col xs={12} lg={"auto"} className="mb-3 mb-lg-0">
            <FloatingLabel label="Points For Completion">
              <Form.Control
                type="number"
                placeholder="Points For Completion"
                value={props.event.pointsCompletion || ""}
                onChange={(e) =>
                  updatePoints("pointsCompletion", e.target.value)
                }
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} lg={"auto"} className="mb-3 mb-lg-0">
            <FloatingLabel label="Bonus Points For a 70%">
              <Form.Control
                type="number"
                placeholder="Bonus Points For a 70%"
                value={props.event.points70 || ""}
                onChange={(e) => updatePoints("points70", e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} lg={"auto"} className="mb-3 mb-lg-0">
            <FloatingLabel label="Bonus Points For a 80%">
              <Form.Control
                type="number"
                placeholder="Bonus Points For a 80%"
                value={props.event.points80 || ""}
                onChange={(e) => updatePoints("points80", e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} lg={"auto"} className="mb-3 mb-lg-0">
            <FloatingLabel label="Bonus Points For a 90%">
              <Form.Control
                type="number"
                placeholder="Bonus Points For a 90%"
                value={props.event.points90 || ""}
                onChange={(e) => updatePoints("points90", e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} lg={"auto"} className="mb-3 mb-lg-0">
            <FloatingLabel label="Bonus Points For a 100%">
              <Form.Control
                type="number"
                placeholder="Bonus Points For a 100%"
                value={props.event.points100 || ""}
                onChange={(e) => updatePoints("points100", e.target.value)}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mt-3 gx-1">
          <Col>
            <Editor
              tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
              value={ReactDOMServer.renderToString(
                ReactParseHTML(props.event.notes || "")
              )}
              onEditorChange={(newValue) =>
                updateNotes(removeLineBreaks(newValue))
              }
              init={{
                height: 250,
                branding: false,
                menubar: false,
                plugins: "link,lists",
                toolbar:
                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link | forecolor backcolor",
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={props.onClose}>
            Close
          </Button>
          <Button onClick={props.onSave}>Close &amp; Save</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default RoutineEventDetails;
