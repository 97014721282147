import { useState } from "react";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import { ArrowRepeat } from "react-bootstrap-icons";

const CalendarDayTemplateSelector = (props) => {
  const [removeCurrent, setRemoveCurrent] = useState(false);

  const applyDayTemplate = (dayTemplate) => {
    if (removeCurrent) {
      props.events.forEach((event, index) => props.removeEvent(event, index));
    }

    const events = [...props.events];

    dayTemplate.events.map((event) =>
      events.push({
        ...event,
        id: null,
        fk_studentId: event.fk_studentId,
        date: props.date,
      })
    );

    props.updateEvents(events);

    const studentIds = props.students.map((student) => student.id);

    props.updateStudentData(props.students, [
      ...new Set(
        events.map(
          (event) => props.students[studentIds.indexOf(event.fk_studentId)]
        )
      ),
    ]);

    props.onClose();
  };

  return (
    <Modal centered size="md" show={props.show} onHide={props.onClose}>
      <Modal.Header
        className="bg-dark text-light h5"
        closeButton
        closeVariant="white"
      >
        <ArrowRepeat />
        &nbsp; Apply Day Template
      </Modal.Header>
      <ModalBody className="text-left">
        <Form.Check
          type="switch"
          label="Remove current events"
          checked={removeCurrent}
          onChange={() => setRemoveCurrent(!removeCurrent)}
        />

        {props.dayTemplates.map((dayTemplate) => (
          <Button
            key={`dayTemplate-${dayTemplate.id}`}
            variant="link"
            className="text-decoration-none w-100 text-start"
            onClick={() => applyDayTemplate(dayTemplate)}
          >
            {dayTemplate.title}
          </Button>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default CalendarDayTemplateSelector;
