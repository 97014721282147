import { Button, ButtonGroup, Modal, ModalBody } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";

const Confirm = (props) => (
  <Modal centered size="md" show={props.show} onHide={() => props.onClose()}>
    <Modal.Header
      className="bg-dark text-light h5"
      closeButton
      closeVariant="white"
    >
      <ExclamationCircleFill />
      &nbsp; {props.header}
    </Modal.Header>
    <ModalBody className="text-left">
      <div className="lead">
        {props.message}
        <ButtonGroup className="mt-3 float-end">
          <Button variant="primary" onClick={() => props.onCancel()}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => props.onConfirm()}>
            {props.confirmText}
          </Button>
        </ButtonGroup>
      </div>
    </ModalBody>
  </Modal>
);

export default Confirm;
