import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CalendarEventBar from "../Calendar/CalendarEventBar";
import CalendarHour from "../Calendar/CalendarHour";

const RoutineAgenda = (props) => {
  const [height, setHeight] = useState(4.5);
  const hours = [];
  for (var h = 0; h <= 23; h++) hours.push(h);

  return (
    <div className="calendar mt-3">
      <Card className="border-none">
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <div className="day" onScroll={props.onScroll}>
                <div className="container" style={{ height: `${height}rem` }}>
                  <div className="timeline">
                    {hours.map((hour, index) => (
                      <CalendarHour
                        key={`hour-${props?.student?.id}-${index}`}
                        studentId={props?.student?.id}
                        hour={hour}
                        addEvent={props.addEvent}
                      />
                    ))}
                  </div>
                  {props.events.map((event, index) =>
                    event?.fk_studentId === props?.student?.id &&
                    !event?.deactivatedDateTime ? (
                      <CalendarEventBar
                        key={`calendarEvenBar-${event.fk_studentId}-${index}`}
                        events={props.events}
                        event={event}
                        index={index}
                        removeEvent={props.removeEvent}
                        setActiveEvent={props.setActiveEvent}
                        setHeight={setHeight}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RoutineAgenda;
