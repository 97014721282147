import { Carousel, Col, Container, Image, Ratio, Row } from "react-bootstrap";
import {
  ArrowRepeat,
  Bell,
  CardList,
  Check2Circle,
  CurrencyDollar,
  GearWideConnected,
  GraphUpArrow,
  Incognito,
  People,
  PlayBtn,
  Trophy,
} from "react-bootstrap-icons";
import HomeFeature from "./HomeFeature";

const Home = () => (
  <Container fluid className="home">
    <Row className="text-center">
      <Col>
        <Image className="image-fluid w-50 my-3" src="ourschoolhub.png" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col className="h1 text-center">
        Our Children. Our Future. Our School Hub.
      </Col>
    </Row>

    <Row className="mt-3">
      <Col>
        <p>
          There are numerous reasons to homeschool. Perhaps you aren't satisfied
          with school safety. Perhaps you disagree with the public curriculum.
          Perhaps you simply believe parents and family should play an intimate
          role in education. Yet, no matter how honourable and practical the
          reason, homeschooling can be daunting. <i>Our School Hub</i> aims to
          relieve, at least, some of those fears.
        </p>
        <p>
          <i>Our School Hub</i> is a <strong>completely free</strong>,
          curriculum-independent platform for homeschooling.{" "}
          <i>Our School Hub</i> helps structure your and your students' days.
          With <i>Our School Hub</i> you can plan a day, create routines, assign
          tasks. While your students can independently mark tasks completed,
          accumulate points and claim prizes! So, signup now and let{" "}
          <i>Our School Hub</i> make homeschooling that much easier! Read below
          (and watch this{" "}
          <a
            rel="noReferrer"
            href="https://rumble.com/v12ifdl-our-school-hub-short-ad.html"
            target="_blank"
          >
            intro
          </a>{" "}
          ,{" "}
          <a
            rel="noReferrer"
            href="https://rumble.com/v12m7ax-overview-of-our-school-hub.html"
            target="_blank"
          >
            tour
          </a>{" "}
          and{" "}
          <a
            rel="noReferrer"
            href="https://rumble.com/v12o3cx-in-depth-tutorial-for-our-school-hub.html"
            target="_blank"
          >
            tutorial
          </a>
          ) to see benefits that <i>Our School Hub</i> provides.
        </p>
      </Col>
    </Row>
    <Row className="my-5">
      <Col className="h1 text-center">
        <a className=" text-decoration-none" href="/login">
          Click here to signup! It's 100% free &amp; doesn't require an email!
        </a>
      </Col>
    </Row>
    <Row className="my-5">
      <Col className="h1 text-center">
        <Carousel>
          <Carousel.Item>
            <Ratio aspectRatio="21x9">
              <iframe
                title="Intro to Our School Hub"
                class="rumble"
                src="https://rumble.com/embed/vzw9dv/?pub=107jef"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </Ratio>
            <Carousel.Caption>
              <div className="fs-5">Quick intro to Our School Hub</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Ratio aspectRatio="21x9">
              <iframe
                title="Overview of Our School Hub"
                class="rumble"
                src="https://rumble.com/embed/v1001bh/?pub=107jef"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </Ratio>
            <Carousel.Caption>
              <div className="fs-5">Tour of Our School Hub</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Ratio aspectRatio="21x9">
              <iframe
                title="Overview of Our School Hub"
                class="rumble"
                src="https://rumble.com/embed/v106q39/?pub=107jef"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </Ratio>
            <Carousel.Caption>
              <div className="fs-5">In-Depth Tutorial of Our School Hub</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/calendar.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Populate your calendar with student tasks!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/students.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Create and manage virtually unlimited students!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/routine.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Create routines for repetative student agendas!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/dayTemplate.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Create day templates for redundant day schedules!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/prizes.png" />
            <Carousel.Caption>
              <div className="fs-5">Create prizes as incentives!</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/markComplete.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Students can login and independently mark tasks complete!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/earnPoints.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Students can earn points and use them to claim prizes!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/notifications.png" />
            <Carousel.Caption>
              <div className="fs-5">
                Be notified when students complete tasks or raise their hands!
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="/slides/analytics.png" />
            <Carousel.Caption>
              <div className="fs-5">Moniter your students' progress!</div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<CurrencyDollar className="align-top" />}
        title="Completely Free"
      >
        <i>Our School Hub</i> is 100% free! There is no "pro" package. There are
        no features hidden behind a pay wall.
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<Incognito className="align-top" />}
        title="Privacy"
        right
      >
        Feel more secure knowing <i>Our School Hub</i> does not require an email
        to signup or login! Not for you and not for your students!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<PlayBtn className="align-top" />}
        title="Video Tutorials"
      >
        <i>Our School Hub</i> provides numerous <a href="/help">tutorials</a> to
        help you get started! Learn how to create and manage students, create
        prizes, populate your calendar, and more!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<People className="align-top" />}
        title="Virtually Unlimited Students"
        right
      >
        Create virtually as many students as you like!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<ArrowRepeat className="align-top" />}
        title="Routines"
      >
        Do your students typically follow the same schedule? Simplify scheduling
        with routines!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<CardList className="align-top" />}
        title="Day Templates"
        right
      >
        You may find that your students repeat the same schedule on Mondays and
        Wednesdays, and another schedule on Tuesday and Thursdays. Reduce effort
        with Day Templates!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<Trophy className="align-top" />}
        title="Points &amp; Prizes"
      >
        Create prizes that students can claim with points. Then assign points
        for completion and additional points for scoring 70%, 80%, 90% or 100%.
        Students can then accumlate points and claim prizes!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<Check2Circle className="align-top" />}
        title="Student Independance"
        right
      >
        Students can login, independently mark tasks completed, garner points,
        request help, and claim prizes!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature icon={<Bell className="align-top" />} title="Notifications">
        You have the option to be remotely notified (on all devices that support
        web notifications) when students complete tasks or raise their hands!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<GraphUpArrow className="align-top" />}
        title="Analytics"
        right
      >
        Track your students' progress. View their point accumulation, average
        grades per subject, and more!
      </HomeFeature>
    </Row>
    <Row className="my-3">
      <HomeFeature
        icon={<GearWideConnected className="align-top" />}
        title="Upgrades &amp; New Features"
      >
        <i>Our School Hub</i> is an evolving platform. We want to keep bettering
        the system and provide more tools to improve your homeschooling
        experience!
      </HomeFeature>
    </Row>
    <Row className="my-5">
      <Col className="h1 text-center">
        <a className=" text-decoration-none" href="/login">
          Click here to signup! It's 100% free &amp; doesn't require an email!
        </a>
      </Col>
    </Row>
  </Container>
);

export default Home;
