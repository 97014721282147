import { Card, Col, Row } from "react-bootstrap";

const HomeFeature = (props) => {
  return (
    <Col>
      <Card className="h-100">
        <Card.Body>
          {!props.right && (
            <Row className="align-top text-center">
              <Col sm={2} className="align-top" style={{ fontSize: "6rem" }}>
                {props.icon}
              </Col>
              <Col className="text-start">
                <div className="h3 pb-3 border-bottom">{props.title}</div>
                <div className="mb-3">{props.children}</div>
              </Col>
            </Row>
          )}
          {!!props.right && (
            <Row className="align-top text-center">
              <Col
                sm={2}
                className="align-top order-sm-2"
                style={{ fontSize: "6rem" }}
              >
                {props.icon}
              </Col>
              <Col className="text-start text-sm-end order-1">
                <div className="h3 pb-3 border-bottom">{props.title}</div>
                <div className="mb-3">{props.children}</div>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default HomeFeature;
