import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Check2Circle, ExclamationCircleFill } from "react-bootstrap-icons";
import axios from "axios";
import Dialog from "../../Shared/Dialog";
import RoutineAgenda from "./RoutineAgenda";
import RoutineEventDetails from "./RoutineEventDetails";
import ReturnLink from "../../Shared/Return";
import padZeros from "../../Shared/Util";

const RoutineEdit = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [scroll, setScroll] = useState(0);
  const [activeEvent, setActiveEvent] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    routine: { id: props.id, title: null },
    events: [],
  });

  const addEvent = (studentId, hour) => {
    const newEvent = {
      id: null,
      fk_studentId: studentId,
      fk_subjectId: "2aedf884-b6c1-11ec-ab0f-002590aaf58e",
      date: `${props.year}-${props.month}-${props.day}`,
      start: `${padZeros(hour, 2)}:00`,
      end: `${padZeros(hour + 1, 2)}:00`,
      title: "",
      notes: "",
      pointsCompletion: null,
      points70: null,
      points80: null,
      points90: null,
      points100: null,
      deactivatedDateTime: null,
    };

    setActiveEvent(newEvent);
    setFormData({
      ...formData,
      events: [...formData.events, newEvent],
    });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const purgeEvents = () => {
    const events = formData.events.filter((event) =>
      event.id || !event.deactivatedDateTime ? event : null
    );
    setFormData({ ...formData, events: [...events] });
    return events;
  };

  const removeEvent = (event, index) => {
    formData.events[index].deactivatedDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setFormData({ ...formData, events: [...formData.events] });
    if (event === activeEvent) setActiveEvent(null);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    setActiveEvent(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Routine"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/routineEdit",
        qs.stringify({
          userId: formData.userId,
          routine: JSON.stringify(formData.routine),
          events: JSON.stringify(purgeEvents()),
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({
            ...formData,
            routine: JSON.parse(response.data.routine),
            events: JSON.parse(response.data.events),
          });
          showDialog(
            "Routine & events created/updated successfully!",
            "Routine"
          );
        }
      });
  };

  const updateEvents = () => {
    const events = [...formData.events].sort((a, b) => {
      const startA = new Date(`2000-01-01 ${a.start}`).getTime();
      const startB = new Date(`2000-01-01 ${b.start}`).getTime();

      if (startA < startB) return -1;
      else if (startA > startB) return 1;
      else return 0;
    });

    setFormData({ ...formData, events: [...events] });
  };

  const updateTitle = (e) => {
    formData.routine.title = e.target.value;
    setFormData({ ...formData });
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/routine/id/${props.id}`)
      .then((response) => {
        setFormData({
          userId: props.userId,
          routine: {
            ...{ id: null, title: null },
            ...JSON.parse(response.data.routine),
          },
          events: JSON.parse(response.data.events),
        });
      });
  }, [props.id, props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData) return false;

  return (
    <div className="calendar">
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <RoutineEventDetails
        show={!!activeEvent}
        events={formData.events}
        event={activeEvent}
        index={formData.events.indexOf(activeEvent)}
        updateEvents={updateEvents}
        onSave={submit}
        onClose={() => setActiveEvent(null)}
      />

      <Row className="mt-3">
        <Col>
          <ReturnLink href="/myroutines" label="Routines" />
        </Col>
      </Row>

      {!(formData.events || []).length && (
        <Alert variant="info" className="mt-3">
          <ExclamationCircleFill /> You currently haven't added any tasks to
          this routine. To do so, click on the appropriate hour within the
          timeline.
        </Alert>
      )}

      <Row className="mt-3">
        <Col>
          <FloatingLabel label="Title">
            <Form.Control
              placeholder="Title"
              value={formData.routine.title || ""}
              onChange={updateTitle}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Row>
      <RoutineAgenda
        events={formData.events}
        scroll={scroll}
        onScroll={(e) => setScroll(e.target.scrollLeft)}
        addEvent={addEvent}
        setActiveEvent={setActiveEvent}
        removeEvent={removeEvent}
      />
      <Row className="mt-3">
        <Col md={8}></Col>
        <Col className="text-end">
          <Button variant="primary" onClick={() => submit()}>
            <Check2Circle></Check2Circle>&nbsp;Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RoutineEdit;
