import { ProgressBar } from "react-bootstrap";
import { minutesToHoursMinutes } from "./Util";

const ProgressNote = (props) => {
  const percentage = props.milestone ? props.milestone / props.goal : 0;

  return (
    <>
      <small className="p-2">
        <strong>{props.label}: </strong>
        {!!props.isMinutes
          ? `${minutesToHoursMinutes(
              props.milestone
            )} of ${minutesToHoursMinutes(props.goal)} ${props.suffix}`
          : `${props.milestone} of ${props.goal} ${props.suffix}`}
      </small>
      <ProgressBar
        variant={
          percentage >= 0.9
            ? "success"
            : percentage >= 0.8
            ? "info"
            : percentage >= 0.7
            ? "primary"
            : percentage >= 0.5
            ? "warning"
            : "danger"
        }
        now={props.milestone}
        max={props.goal}
        style={{ height: "5px" }}
      />
    </>
  );
};

export default ProgressNote;
