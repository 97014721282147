import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import Dialog from "../Shared/Dialog";
import { dateToString, toBoolean, ymdToDate } from "../Shared/Util";
import StudenDayAgenda from "./StudentDayAgenda";
import StudentEventDetails from "./StudentEventDetails";
import StudenDayAgendaList from "./StudentDayAgendaList";
import DateHeader from "../Shared/DateHeader";
import StudentPoints from "./StudentPoints";
import StudentDialogGrade from "./StudentDialogGrade";
import moment from "moment";
import { EmojiSmile } from "react-bootstrap-icons";

const SudentDay = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  const [focusEvent, setFocusEvent] = useState(null);
  const [grade, setGrade] = useState(null);
  const [gradeEvent, setGradeEvent] = useState(null);
  const [events, setEvents] = useState(null);
  const [points, setPoints] = useState(null);
  const [pointsAvailable, setPointsAvailable] = useState(null);
  const [dialogGrade, setDialogGrade] = useState(null);

  const dateLeft = () => {
    const date = dateToString(ymdToDate(props.year, props.month, props.day));
    const newDate = moment(date).subtract(1, "days");

    window.location = `/myday/year/${newDate.format(
      "YYYY"
    )}/month/${newDate.format("MM")}/day/${newDate.format("DD")}`;
  };

  const dateRight = () => {
    const date = dateToString(ymdToDate(props.year, props.month, props.day));
    const newDate = moment(date).add(1, "days");

    window.location = `/myday/year/${newDate.format(
      "YYYY"
    )}/month/${newDate.format("MM")}/day/${newDate.format("DD")}`;
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const showGradeDialog = (event) => {
    if (toBoolean(event.completed)) toggleEventComplete(event);
    else {
      setGradeEvent(event);
      setDialogGrade(true);
    }
  };

  const toggleEventComplete = async (event) => {
    setDialogGrade(false);
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "My Day"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/studentEventUpdate",
        qs.stringify({
          userId: props.userId,
          eventId: event.id,
          completed: toBoolean(event.completed) ? 0 : 1,
          grade: grade,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        hideDialog();
        if (!toBoolean(event.completed)) setActiveEvent(null);
        event.percentage = grade;
        event.completed = !toBoolean(event.completed);
        updateEvents();
        setGrade(null);
        setPoints(response.data.points);
        setPointsAvailable(response.data.pointsAvailable);
      });
  };

  const updateEvents = () => {
    setEvents([...events]);
  };

  const _getData = useCallback(async () => {
    const date = dateToString(ymdToDate(props.year, props.month, props.day));

    await axios
      .get(
        `https://api.ourschoolhub.online/studentDay/id/${props.userId}/date/${date}`
      )
      .then((response) => {
        const responseEvents = JSON.parse(response.data.events);
        setEvents(responseEvents);
        if (responseEvents.length) setFocusEvent(responseEvents[0]);
        setPoints(response.data.points);
        setPointsAvailable(response.data.pointsAvailable);
      });
  }, [props.year, props.month, props.day, props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!events) return false;

  return (
    <Form className="calendar">
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <StudentEventDetails
        show={!!activeEvent}
        event={activeEvent}
        toggleEventComplete={showGradeDialog}
        onClose={() => setActiveEvent(null)}
      />

      <StudentDialogGrade
        show={dialogGrade}
        grade={grade}
        onClose={() => setDialogGrade(false)}
        onCancel={() => setDialogGrade(false)}
        onChange={(e) => setGrade(e.target.value)}
        onContinue={() => toggleEventComplete(gradeEvent)}
      />

      <Row className="mt-3">
        <Col>
          <StudentPoints points={points} pointsAvailable={pointsAvailable} />
        </Col>
      </Row>

      <Row>
        <Col md={4} xxl={4}>
          <DateHeader
            date={ymdToDate(props.year, props.month, props.day)}
            onDateLeft={dateLeft}
            onDateRight={dateRight}
          />
        </Col>
        <Col md={8} xxl={8}>
          <StudenDayAgenda
            student={{ id: props.userId }}
            events={events}
            focusEvent={focusEvent}
            setActiveEvent={setActiveEvent}
          />
        </Col>
      </Row>

      <div className="my-5 bg-primary text-light p-3 studentHeader">
        <EmojiSmile /> Hello! Here are today's tasks.
      </div>
      <StudenDayAgendaList
        student={{ id: props.userId }}
        events={events}
        setActiveEvent={setActiveEvent}
      />
    </Form>
  );
};

export default SudentDay;
