import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import InputDate from "../../Input/InputDate";
import { Alert, Card, Col, Row } from "react-bootstrap";
import padZeros, {
  dateToString,
  dateToTimeString,
  timeToDate,
} from "../../Shared/Util";
import {
  Check2Square,
  Clock,
  ExclamationCircleFill,
  People,
} from "react-bootstrap-icons";
import TooltipOverlay from "../../TooltipOverlay";
import PageIntro from "../../Shared/PageInto";

const Calendar = (props) => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateMonth, setDateMonth] = useState(moment().format("MM"));
  const [dateYear, setDateYear] = useState(moment().format("YYYY"));
  const [firstDay, setFirstDay] = useState(
    new Date(dateYear, parseInt(dateMonth) - 1, 1)
  );
  const [studentCount, setStudentCount] = useState(null);
  const [events, setEvents] = useState(null);
  const [days, setDays] = useState([]);
  const [daysPrefix, setDaysPrefix] = useState([]);

  const isLarge = useMediaQuery({ query: "(min-width: 992px)" });

  const displayDateInfo = (pre, date) => {
    const event = getEvent(date);

    return (
      !!event && (
        <div>
          <div>
            <TooltipOverlay tip="Number of Students">
              <span>
                <People />
                &nbsp;
                {event.students}
              </span>
            </TooltipOverlay>
          </div>
          <div>
            <TooltipOverlay tip="Number of Tasks">
              <span>
                <Check2Square />
                &nbsp;
                {event.events}
              </span>
            </TooltipOverlay>
          </div>
          <div>
            <TooltipOverlay tip="Starting Time">
              <span>
                <Clock />
                &nbsp;
                {dateToTimeString(timeToDate(event.start))}
              </span>
            </TooltipOverlay>
          </div>
        </div>
      )
    );
  };

  const getEvent = (date) =>
    events.map((event) => event.date).indexOf(dateToString(date)) >= 0
      ? events[events.map((event) => event.date).indexOf(dateToString(date))]
      : null;

  const updateDate = (value) => {
    setDate(value);
    setDateMonth(moment(value).format("MM"));
    setDateYear(moment(value).format("YYYY"));
  };

  const _getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourschoolhub.online/calendar/id/${
          props.userId
        }/datePattern/${padZeros(dateYear, 4)}-${padZeros(
          firstDay.getMonth(),
          2
        )}|${padZeros(dateMonth, 2)}|-`
      )
      .then((response) => {
        setStudentCount(response.data.studentCount);
        setEvents(JSON.parse(response.data.events));
      });
  }, [props.userId, dateMonth, dateYear, firstDay]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  useEffect(() => {
    const newDays = [];

    for (
      var d = 1;
      d <=
      new Date(
        moment(date).format("YYYY"),
        moment(date).format("MM"),
        0
      ).getDate();
      d++
    )
      newDays.push(d);

    const newFirstDay = new Date(dateYear, parseInt(dateMonth) - 1, 1);
    const firstDayOfWeek = parseInt(newFirstDay.getDay());

    const newDaysPrefix = [];
    for (var dp = 0; dp < firstDayOfWeek; dp++)
      newDaysPrefix.push(
        new Date(moment(newFirstDay).subtract(firstDayOfWeek - dp, "days"))
      );

    setDays(newDays);
    setDaysPrefix(newDaysPrefix);
    setFirstDay(newFirstDay);
  }, [date, dateYear, dateMonth]);

  if (!events) return null;

  return (
    <>
      <PageIntro>
        It's time to fill your calendar! You can customize each day manually, or
        simplify the process using the <a href="/myroutines">routines</a> and{" "}
        <a href="/mydaytemplates">day&nbsp;templates</a> you created. As soon as
        you setup a day in your calendar, your students can login and view their
        tasks!
      </PageIntro>

      {!studentCount && (
        <Alert variant="danger">
          <a href="/mystudents" className="text-decoration-none text-danger">
            <ExclamationCircleFill /> You currently have no students. Click here
            to add students.
          </a>
        </Alert>
      )}

      <Row className="mt-3">
        <Col md={8}></Col>
        <Col>
          <InputDate
            hideDay={true}
            date={date}
            onChange={(value) => updateDate(value)}
          />
        </Col>
      </Row>

      <Row className="mt-3 fw-bold d-none d-lg-flex">
        <Col>Sunday</Col>
        <Col>Monday</Col>
        <Col>Tuesday</Col>
        <Col>Wednesday</Col>
        <Col>Thursday</Col>
        <Col>Friday</Col>
        <Col>Saturday</Col>
      </Row>

      <Row>
        {isLarge &&
          daysPrefix.map((day, index) => (
            <Col
              sm={6}
              md={4}
              lg={3}
              key={`date-${index}`}
              className={`${isLarge && "date"} mt-3`}
            >
              <a
                className="text-decoration-none text-secondary"
                href={`/mycalendar/year/${padZeros(
                  day.getFullYear(),
                  4
                )}/month/${padZeros(day.getMonth() + 1, 2)}/day/${padZeros(
                  day.getDate(),
                  2
                )}`}
              >
                <Card>
                  <Card.Body>
                    <div className="text-end border-bottom  mb-1">
                      {`${day.getMonth() + 1}/${day.getDate()}`}
                    </div>
                    {displayDateInfo(true, day)}
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))}

        {days.map((day, index) => {
          const isToday =
            dateToString(new Date(dateYear, dateMonth - 1, day)) ===
            moment().format("YYYY-MM-DD");

          return (
            <Col
              sm={6}
              md={4}
              lg={3}
              key={`date-${index}`}
              className={`${isLarge && "date"} mt-3`}
            >
              <a
                className="text-decoration-none text-dark"
                href={`/mycalendar/year/${dateYear}/month/${dateMonth}/day/${day}`}
              >
                <Card border={isToday ? "primary" : "dark"}>
                  <Card.Body>
                    <div
                      className={`text-end border-bottom mb-1 ${
                        isToday && "text-primary border-primary"
                      }`}
                    >
                      {day}
                    </div>
                    {displayDateInfo(
                      false,
                      new Date(dateYear, dateMonth - 1, day)
                    )}
                  </Card.Body>
                </Card>
              </a>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Calendar;
