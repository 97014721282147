import { Button, Col, Row } from "react-bootstrap";
import { QuestionSquare } from "react-bootstrap-icons";

const GuideButton = (props) => {
  const clearGuideCookies = () => {
    props.setCookie("guideStudentsComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guideCalendarComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guideRoutinesComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guideDayTemplatesComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guidePrizesComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guideAnalyticsComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setCookie("guideTutorialsComplete", false, {
      maxAge: 90 * 24 * 60 * 60,
    });

    props.setShow(true);
    props.setIndex("0");
  };

  return (
    <Button
      variant="light"
      onClick={() => clearGuideCookies()}
      className="w-100"
    >
      <Row>
        <Col xs={3}>
          <QuestionSquare className="h1" />
        </Col>
        <Col>
          <div className="h-100 h5 ps-3 border-start text-start">
            What should I do next?
          </div>
        </Col>
      </Row>
    </Button>
  );
};

export default GuideButton;
