import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import ReactParseHTML from "react-html-parser";
import { Badge, Button, Col, Row, Spinner, Tabs } from "react-bootstrap";
import StudentPoints from "./StudentPoints";
import { Boxes, CashCoin, CupStraw, Dribbble } from "react-bootstrap-icons";
import Confirm from "../Shared/Confirm";
import Dialog from "../Shared/Dialog";
import { Tab } from "bootstrap";

const StudentPrizes = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [points, setPoints] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [claims, setClaims] = useState([]);

  const claimPrize = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Prizes"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/studentClaimPrize",
        qs.stringify({
          userId: props.userId,
          prizeId: confirm.id,
          points: confirm.points,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        showDialog("Congratulations! You just claimed a prize!", "Prizes");
        setConfirm(null);
        setClaims(JSON.parse(response.data.claims));
        setPoints(response.data.points);
      });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const _getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourschoolhub.online/studentPrizes/userId/${props.userId}`
      )
      .then((response) => {
        setPrizes(JSON.parse(response.data.prizes));
        setClaims(JSON.parse(response.data.claims));
        setPoints(response.data.points);
      });
  }, [props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!prizes) return false;
  return (
    <>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={confirm}
        header="Prize"
        message={`Are you sure you want to claim this prize for ${
          confirm?.points
        } ${confirm?.points - points === 1 ? "point" : "points"}?`}
        confirmText="Yes, Claim Prize"
        onClose={() => setConfirm(null)}
        onCancel={() => setConfirm(null)}
        onConfirm={() => claimPrize()}
      />

      <Row className="mt-3">
        <Col>
          <StudentPoints points={points} />
        </Col>
      </Row>
      <Tabs className="mt-3">
        <Tab eventKey="shop" title="Shop">
          <Row className="mt-3">
            {prizes.map((prize) => (
              <Col key={`prize-${prize.id}`} md={4}>
                <div
                  className={`mb-3 p-3 border studentPrize ${
                    parseInt(prize.points) <= parseInt(points)
                      ? "bg-primary text-light"
                      : ""
                  }`}
                >
                  <Row>
                    <Col xs={2} md={4}>
                      <Badge
                        className={`points ${
                          parseInt(prize.points) <= parseInt(points)
                            ? "bg-success text-light"
                            : "bg-light text-dark"
                        }`}
                      >
                        {prize.points} Pts
                      </Badge>
                      <div className="display-1 text-center align-top">
                        {prize.fk_typeId ===
                          "c25dbf5c-b9c5-11ec-ab0f-002590aaf58e" && (
                          <Dribbble />
                        )}
                        {prize.fk_typeId ===
                          "c26b754f-b9c5-11ec-ab0f-002590aaf58e" && (
                          <CashCoin />
                        )}
                        {prize.fk_typeId ===
                          "c2718e81-b9c5-11ec-ab0f-002590aaf58e" && <Boxes />}
                        {prize.fk_typeId ===
                          "c2655cc5-b9c5-11ec-ab0f-002590aaf58e" && (
                          <CupStraw />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="title h5 pb-1 border-bottom">
                        {prize.title}
                      </div>
                      <div className="description">
                        {ReactParseHTML(prize.description)}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                        className="bg-light text-dark"
                        disabled={parseInt(prize.points) > parseInt(points)}
                        onClick={() => {
                          if (parseInt(prize.points) <= parseInt(points))
                            setConfirm(prize);
                        }}
                      >
                        Claim!
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Tab>
        <Tab eventKey="claims" title="Claims">
          <Row className="mt-3">
            {claims.map((prize) => (
              <Col key={`prize-${prize.id}`} md={4}>
                <div
                  className={`mb-3 p-3 border studentPrize ${
                    parseInt(prize.points) <= parseInt(points)
                      ? "bg-primary text-light"
                      : ""
                  }`}
                >
                  <Row>
                    <Col xs={2} md={4}>
                      <Badge
                        className={`points ${
                          parseInt(prize.points) <= parseInt(points)
                            ? "bg-success text-light"
                            : "bg-light text-dark"
                        }`}
                      >
                        {prize.points} Pts
                      </Badge>
                      <div className="display-1 text-center align-top">
                        {prize.fk_typeId ===
                          "c25dbf5c-b9c5-11ec-ab0f-002590aaf58e" && (
                          <Dribbble />
                        )}
                        {prize.fk_typeId ===
                          "c26b754f-b9c5-11ec-ab0f-002590aaf58e" && (
                          <CashCoin />
                        )}
                        {prize.fk_typeId ===
                          "c2718e81-b9c5-11ec-ab0f-002590aaf58e" && <Boxes />}
                        {prize.fk_typeId ===
                          "c2655cc5-b9c5-11ec-ab0f-002590aaf58e" && (
                          <CupStraw />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="title h5 pb-1 border-bottom">
                        {prize.title}
                      </div>
                      <div className="description">
                        {ReactParseHTML(prize.description)}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Tab>
      </Tabs>
    </>
  );
};

export default StudentPrizes;
