import { useEffect, useState } from "react";
import { Check2Circle, XCircleFill } from "react-bootstrap-icons";
import { calculateOverlaps, toBoolean } from "../../Shared/Util";
import TooltipOverlay from "../../TooltipOverlay";

const CalendarEventBar = (props) => {
  const [overlaps, setOverlaps] = useState(null);
  const minuteWidth = 300 / 60;

  const propsStart = props?.event.start
    ? new Date(`2000-01-01T${props.event.start}`)
    : new Date();

  const propsEnd = props?.event.end
    ? new Date(`2000-01-01T${props.event.end}`)
    : new Date();

  const left =
    propsStart.getHours() * 300 + propsStart.getMinutes() * minuteWidth;

  const right = propsEnd.getHours() * 300 + propsEnd.getMinutes() * minuteWidth;

  const removeEvent = (e) => {
    e.stopPropagation();
    props.removeEvent(props.event, props.index);
  };

  useEffect(() => {
    const overlaps = calculateOverlaps(props.events, props.event);
    props.setHeight((Math.max(...overlaps) + 1) * 2.25);
    setOverlaps(overlaps);
  }, [props, props.events, props.event]);

  if (!overlaps) return null;

  return (
    <TooltipOverlay tip={props.event.title}>
      <div
        className={`hourBar cursorPointer ${
          toBoolean(props.event.completed)
            ? "completed bg-success text-light"
            : overlaps[props.index] > 1
            ? "conflict bg-warning text-dark"
            : "bg-light text-dark"
        } p-1 rounded-3`}
        style={{
          left: `${left}px`,
          width: `${right - left}px`,
          top: `${2.25 * overlaps[props.index]}rem`,
        }}
        onClick={() => props.setActiveEvent(props.event)}
      >
        <span className="title">
          {toBoolean(props.event.completed) && (
            <>
              <Check2Circle />
              &nbsp;
            </>
          )}
          {props.event.title}
        </span>
        {!!props.removeEvent && (
          <XCircleFill
            className="hourBarClose text-light"
            onClick={removeEvent}
          />
        )}
      </div>
    </TooltipOverlay>
  );
};

export default CalendarEventBar;
