import { Button, Col, Row } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const DateHeader = (props) => {
  return (
    <div>
      <Row>
        <Col className="display-5 border-bottom mb-3">
          {props.date.toLocaleDateString("en-us", { weekday: "long" })}
          <span>
            <Button
              variant="link"
              className="text-decoration-none"
              onClick={props.onDateLeft}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="link"
              className="text-decoration-none"
              onClick={props.onDateRight}
            >
              <ChevronRight />
            </Button>
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="display-6">
          {props.date.toLocaleDateString("en-us", { month: "long" })}
          &nbsp;
          {props.date.getDate()}
          ,&nbsp;
          {props.date.getFullYear()}
        </Col>
      </Row>
    </div>
  );
};

export default DateHeader;
