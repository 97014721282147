import { useEffect } from "react";
import OneSignal from "react-onesignal";

const PushNotification = (props) => {
  useEffect(() => {
    OneSignal.init({ appId: "49102aae-c778-4835-aeff-88720b2913b4" }).then(
      () => {
        OneSignal.setExternalUserId(props.userId);
        OneSignal.showSlidedownPrompt().then(() => {});
      }
    );
  }, [props.userId]);

  return <></>;
};

export default PushNotification;
