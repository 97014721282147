const agendaTime = () => {
  const now = new Date();
  const minuteWidth = 300 / 60;

  return now.getHours() * 300 + now.getMinutes() * minuteWidth;
};

const calculateEventOverlaps = (uOverlaps, uEvents, uEvent, uIndex) => {
  const conflicts = [0];
  const uStart = new Date(`2000-01-01T${uEvent.start}`).getTime();
  const uEnd = new Date(`2000-01-01T${uEvent.end}`).getTime();

  uEvents.forEach((event, index) => {
    if (
      !event.deactivatedDateTime &&
      event.fk_studentId === uEvent.fk_studentId &&
      index < uIndex
    ) {
      const start = new Date(`2000-01-01T${event.start}`).getTime();
      const end = new Date(`2000-01-01T${event.end}`).getTime();

      if (uOverlaps[index] <= conflicts.length) {
        if (start >= uStart && start <= uEnd && end >= uStart && end <= uEnd)
          conflicts.push(uOverlaps[index]);
        else if (start < uStart && end > uStart)
          conflicts.push(uOverlaps[index]);
      }
    }
  });

  return Math.max(...conflicts) + 1;
};

const calculateOverlaps = (uEvents, uEvent) => {
  const overlaps = [];

  uEvents.forEach((event, index) => {
    if (event.fk_studentId === uEvent.fk_studentId)
      overlaps.push(calculateEventOverlaps(overlaps, uEvents, event, index));
    else overlaps.push(0);
  });

  return overlaps;
};

const dateToString = (date) =>
  `${padZeros(date.getFullYear(), 4)}-${padZeros(
    date.getMonth() + 1,
    2
  )}-${padZeros(date.getDate(), 2)}`;

const dateToTimeString = (date) =>
  `${padZeros(
    date.getHours() > 12 ? date.getHours() - 12 : date.getHours() || 12,
    2
  )}:${padZeros(date.getMinutes(), 2)} ${date.getHours() >= 12 ? "PM" : "AM"}`;

const minutesToHoursMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  return `${padZeros(hours, 2)}:${padZeros(minutes - hours * 60, 2)}`;
};

const padZeros = (value, count) => {
  value = `${"0".repeat(count)}${value}`;
  return value.substring(value.length - count);
};

const removeLineBreaks = (text) => text.replace(/\n/g, "");

const stringToDate = (date) => new Date(`${date}T23:59:59`);

const timeToDate = (time) => new Date(`2000-01-01T${time}`);

const toBoolean = (value) => {
  if (value === "false") return false;
  else if (value === "0") return false;
  else if (value === 0) return false;
  else if (value === "true") return true;
  else return value;
};

const ymdToDate = (year, month, day) =>
  !!year && !!month && !!day
    ? stringToDate(
        `${padZeros(year, 4)}-${padZeros(month, 2)}-${padZeros(day, 2)}`
      )
    : new Date();

export default padZeros;
export {
  agendaTime,
  calculateEventOverlaps,
  calculateOverlaps,
  dateToString,
  dateToTimeString,
  minutesToHoursMinutes,
  removeLineBreaks,
  stringToDate,
  timeToDate,
  toBoolean,
  ymdToDate,
};
