import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeSeriesScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import InputDate from "../../Input/InputDate";
import padZeros from "../../Shared/Util";
import PageIntro from "../../Shared/PageInto";
import { install } from "resize-observer";

install();

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeSeriesScale
);

const Analytics = (props) => {
  const [type, setType] = useState("AvgGradeBySubject");
  const [types, setTypes] = useState();
  const [studentId, setStudentId] = useState();
  const [from, setFrom] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = useState(null);
  const [format, setFormat] = useState(null);
  const [pre, setPre] = useState(null);
  const [post, setPost] = useState(null);
  const [students, setStudents] = useState(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            format === "percentage"
              ? `${pre} ${parseFloat(context.raw).toFixed(2)}% ${post}`
              : format === "time"
              ? `${pre} ${padZeros(
                  Math.floor(parseInt(context.raw) / 60),
                  2
                )}:${padZeros(
                  parseInt(context.raw) -
                    Math.floor(parseInt(context.raw) / 60) * 60,
                  2
                )} ${post}`
              : `${pre} ${context.raw} ${post}`,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) =>
            format === "percentage"
              ? `${value}%`
              : format === "time"
              ? `${padZeros(Math.floor(value / 60), 2)}:${padZeros(
                  value - Math.floor(value / 60) * 60,
                  2
                )}`
              : value,
        },
      },
    },
  };

  const getAnalytics = useCallback(async () => {
    if (!!studentId)
      await axios
        .get(
          `https://api.ourschoolhub.online/analytics${type}/student/${studentId}/from/${from}/to/${to}`
        )
        .then((response) => {
          const responseData = JSON.parse(response.data.data);

          setFormat(response.data.format);
          setPre(response.data.pre);
          setPost(response.data.post);

          setData({
            labels: responseData.map((entry) => entry.label),
            datasets: [
              {
                data: responseData.map((entry) => entry.metric),
                backgroundColor: [
                  "#ffaaaa",
                  "#aaffaa",
                  "#aaff",
                  "#ffffaa",
                  "#aaffff",
                  "#ffaaff",
                  "#aaaaaa",
                ],
              },
            ],
          });
        });
  }, [type, studentId, from, to]);

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/analytics/id/${props.userId}`)
      .then((response) => {
        const responseStudents = JSON.parse(response.data.students);
        setStudents(responseStudents);
        if (responseStudents.length) setStudentId(responseStudents[0].id);
        setTypes(JSON.parse(response.data.types));
      });
  }, [props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  useEffect(() => {
    getAnalytics();
  }, [getAnalytics]);

  if (!data) return null;

  return (
    <>
      <PageIntro>
        Monitor your students' progress. You can view how many points they've
        garnered per date, how much time they've spent on each subject, and
        more.
      </PageIntro>

      <Row className="mt-3">
        <Col>
          <FloatingLabel label="Student">
            <Form.Select onChange={(e) => setStudentId(e.target.value)}>
              {(students || []).map((student) => (
                <option key={`student-${student.id}`} value={student.id}>
                  {student.alias || student.login}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col>
          <InputDate
            label="From"
            date={from}
            onChange={(value) => setFrom(value)}
          />
        </Col>
        <Col>
          <InputDate label="To" date={to} onChange={(value) => setTo(value)} />
        </Col>
        <Col>
          <FloatingLabel label="Student">
            <Form.Select
              value={type}
              onChange={(e) => setType(e.target.value.replace(/\s/g, ""))}
            >
              {[...(types || [])].map((type) => (
                <option key={`type-${type}`} value={type.replace(/\s/g, "")}>
                  {type}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Bar className="mb-5" options={options} data={data} />
        </Col>
      </Row>
    </>
  );
};

export default Analytics;
