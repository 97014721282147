import { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { ClipboardPlus, PlusCircle, XCircleFill } from "react-bootstrap-icons";
import Dialog from "../../Shared/Dialog";
import TooltipOverlay from "../../TooltipOverlay";
import Confirm from "../../Shared/Confirm";
import PageIntro from "../../Shared/PageInto";

const Routines = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [remove, setRemove] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    routines: [],
  });

  const duplicateRoutine = async () => {
    const routine = duplicate;
    setDuplicate(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Routine"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/routineDuplicate",
        qs.stringify({
          userId: props.userId,
          routineId: routine.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({ userId: props.userId, routines: response.data });
          showDialog("Routine duplicated successfully!", "Routine");
        }
      });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const removeRoutine = async () => {
    const routine = remove;
    setRemove(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>,
      "Routine"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/routineRemove",
        qs.stringify({
          id: formData.userId,
          routineId: routine.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({ userId: props.userId, routines: response.data });
          showDialog("Routine removed successfully!", "Routine");
        }
      });
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/routineList/id/${props.userId}`)
      .then((response) =>
        setFormData({ userId: props.userId, routines: response.data })
      );
  }, [props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData) return false;

  return (
    <Form>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={!!duplicate}
        header="Duplicate Routine"
        message="Are you certain you want to duplicate this routine?"
        confirmText="Yes, Duplicate Routine"
        onClose={() => setDuplicate(null)}
        onCancel={() => setDuplicate(null)}
        onConfirm={() => duplicateRoutine()}
      />

      <Confirm
        show={!!remove}
        header="Remove Routine"
        message="Are you certain you want to remove this routine?"
        confirmText="Yes, Remove Routine"
        onClose={() => setRemove(null)}
        onCancel={() => setRemove(null)}
        onConfirm={() => removeRoutine()}
      />

      <PageIntro>
        If you find that one or more of your students have recurring, daily
        agendas, you may want to create a routine. Routines are daily schedules
        independent of specific students. Meaning, you don't initially assign
        them to a student. Later (when creating{" "}
        <a href="/mydaytemplates">day templates</a> or filling your{" "}
        <a href="/mycalendar">calendar</a>) you'll be able to assign them to
        students.
      </PageIntro>

      {formData.routines.map((routine, index) => {
        return (
          <Row
            key={`routine${index}`}
            className={`mt-3 ${routine.deactivatedDateTime && "d-none"}`}
          >
            <Col className="display-6 pb-3 border-bottom">
              <a
                className="text-decoration-none"
                href={`/routineEdit/id/${routine.id}`}
              >
                {routine.title}
              </a>
            </Col>
            <Col xs={4} md={1} className="text-end">
              <TooltipOverlay tip="Duplicate Routine">
                <Button
                  variant="link"
                  className="text-dark text-decoration-none"
                  onClick={() => setDuplicate(routine)}
                >
                  <ClipboardPlus />
                </Button>
              </TooltipOverlay>
              <TooltipOverlay tip="Remove Routine">
                <Button
                  variant="link"
                  className="text-danger text-decoration-none"
                  onClick={() => setRemove(routine)}
                >
                  <XCircleFill />
                </Button>
              </TooltipOverlay>
            </Col>
          </Row>
        );
      })}
      <div className="text-end mt-3">
        <ButtonGroup>
          <a className="btn btn-primary" href={`/routineEdit/id/`}>
            <PlusCircle />
            &nbsp;Add Routine
          </a>
        </ButtonGroup>
      </div>
    </Form>
  );
};

export default Routines;
