import { Badge } from "react-bootstrap";

const StudentPoints = (props) => (
  <div className="text-end">
    You have <Badge bg="info">{props.points}</Badge>{" "}
    {props.points === 1 ? "point" : "points"}.
    {!!props.pointsAvailable && (
      <span>
        {" "}
        You can still earn up to <strong>{props.pointsAvailable}</strong>{" "}
        {props.pointsAvailable === 1 ? "point" : "points"}!
      </span>
    )}
  </div>
);

export default StudentPoints;
