import {
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Modal,
  ModalBody,
} from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";

const StudentDialogGrade = (props) => (
  <Modal centered size="md" show={props.show} onHide={() => props.onClose()}>
    <Modal.Header
      className="bg-dark text-light h5"
      closeButton
      closeVariant="white"
    >
      <ExclamationCircleFill />
      &nbsp; Grade
    </Modal.Header>
    <ModalBody className="text-left">
      <div className="lead">
        Did you receive a percentage grade for this task? If so, please enter it
        here.
        <FloatingLabel className="mt-3" label="Percentage Grade">
          <Form.Control
            type="number"
            placeholder="Percentage Grade"
            value={props.grade}
            onChange={props.onChange}
          />
        </FloatingLabel>
        <ButtonGroup className="mt-3 float-end">
          <Button variant="primary" onClick={() => props.onCancel()}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => props.onContinue()}>
            Continue
          </Button>
        </ButtonGroup>
      </div>
    </ModalBody>
  </Modal>
);

export default StudentDialogGrade;
