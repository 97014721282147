import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Check2Circle, Circle } from "react-bootstrap-icons";
import ReactParseHTML from "react-html-parser";
import { toBoolean } from "../Shared/Util";

const StudentEventDetails = (props) => {
  const [hasPoints, setHasPoints] = useState(null);

  useEffect(
    () =>
      setHasPoints(
        !!parseInt(props.event?.pointsCompletion) ||
          !!parseInt(props.event?.points70) ||
          !!parseInt(props.event?.points80) ||
          !!parseInt(props.event?.points90) ||
          !!parseInt(props.event?.points100)
      ),
    [
      props.event?.pointsCompletion,
      props.event?.points70,
      props.event?.points80,
      props.event?.points90,
      props.event?.points100,
    ]
  );
  if (!props.event) return null;

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={props.onClose}
      onEscapeKeyDown={props.onClose}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="text-end bg-primary text-light"
      >
        <div className="float-start display-6">
          {toBoolean(props.event.completed) ? <Check2Circle /> : <Circle />}{" "}
          &nbsp;
          {props.event.title}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          {hasPoints && (
            <Col md={5} className="text-start">
              <ul className="list-group">
                {!!parseInt(props.event.pointsCompletion) && (
                  <li className="list-group-item">
                    Earn{" "}
                    <strong>
                      {props.event.pointsCompletion}{" "}
                      {props.event.pointsCompletion === 1 ? "point" : "points"}
                    </strong>{" "}
                    upon completion!
                  </li>
                )}

                {!!parseInt(props.event.points70) && (
                  <li className="list-group-item">
                    Earn a total of{" "}
                    <strong>
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points70)}{" "}
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points70) ===
                      1
                        ? "point"
                        : "points"}
                    </strong>{" "}
                    if you achieve at least a <strong>70%</strong>!
                  </li>
                )}

                {!!parseInt(props.event.points80) && (
                  <li className="list-group-item">
                    Earn a total of{" "}
                    <strong>
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points80)}{" "}
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points80) ===
                      1
                        ? "point"
                        : "points"}
                    </strong>{" "}
                    if you achieve at least a <strong>80%</strong>!
                  </li>
                )}

                {!!parseInt(props.event.points90) && (
                  <li className="list-group-item">
                    Earn a total of{" "}
                    <strong>
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points90)}{" "}
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points90) ===
                      1
                        ? "point"
                        : "points"}
                    </strong>{" "}
                    if you achieve at least a <strong>90%</strong>!
                  </li>
                )}

                {!!parseInt(props.event.points100) && (
                  <li className="list-group-item">
                    Earn a total of{" "}
                    <strong>
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points100)}{" "}
                      {parseInt(props.event.pointsCompletion) +
                        parseInt(props.event.points100) ===
                      1
                        ? "point"
                        : "points"}
                    </strong>{" "}
                    if you achieve a <strong>100%</strong>!
                  </li>
                )}
              </ul>
            </Col>
          )}
          <Col md={hasPoints ? 7 : 12}>
            {ReactParseHTML(props.event.notes || "")}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!toBoolean(props.event.completed) && (
          <Button
            variant="success"
            onClick={() => props.toggleEventComplete(props.event)}
          >
            <Check2Circle />
            &nbsp;Mark Complete
          </Button>
        )}

        {toBoolean(props.event.completed) && (
          <Button
            variant="secondary"
            onClick={() => props.toggleEventComplete(props.event)}
          >
            <Circle />
            &nbsp;Mark Incomplete
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default StudentEventDetails;
