import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  Check2Circle,
  ExclamationCircleFill,
  PlusCircle,
} from "react-bootstrap-icons";
import axios from "axios";
import Dialog from "../../Shared/Dialog";
import DayTemplateAgenda from "./DayTemplateAgenda";
import DayTemplateEventDetails from "./DayTemplateEventDetails";
import padZeros, { agendaTime } from "../../Shared/Util";
import ReturnLink from "../../Shared/Return";

const DayTemplateEdit = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [routines, setRoutines] = useState(null);
  const [students, setStudents] = useState(null);
  const [availableStudents, setAvailableStudents] = useState([]);
  const [utilizedStudents, setUtilizedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [scroll, setScroll] = useState({ ref: null, value: agendaTime() });
  const [activeEvent, setActiveEvent] = useState(null);
  const [formData, setFormData] = useState({
    userId: props.userId,
    dayTemplate: { id: props.id, title: null },
    events: [],
  });

  const addEvent = (studentId, hour) => {
    const newEvent = {
      id: null,
      fk_dayTemplateId: props.id,
      fk_studentId: studentId,
      fk_subjectId: "2aedf884-b6c1-11ec-ab0f-002590aaf58e",
      start: `${padZeros(hour, 2)}:00`,
      end: `${padZeros(hour + 1, 2)}:00`,
      title: "",
      notes: "",
      pointsCompletion: null,
      points70: null,
      points80: null,
      points90: null,
      points100: null,
      deactivatedDateTime: null,
    };

    setActiveEvent(newEvent);
    setFormData({
      ...formData,
      events: [...formData.events, newEvent],
    });
  };

  const addUtilizedStudent = (uUtilizedStudents) => {
    const availableStudent = updateStudentData(students, [
      ...(uUtilizedStudents || utilizedStudents),
      selectedStudent,
    ]);
    setSelectedStudent(availableStudent);
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const purgeEvents = () => {
    const events = formData.events.filter((event) =>
      event.id || !event.deactivatedDateTime ? event : null
    );
    setFormData({ ...formData, events: [...events] });
    return events;
  };

  const removeEvent = (event, index) => {
    formData.events[index].deactivatedDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setFormData({ ...formData, events: [...formData.events] });
    if (event === activeEvent) setActiveEvent(null);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    setActiveEvent(null);

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Day Template"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/dayTemplateEdit",
        qs.stringify({
          userId: formData.userId,
          dayTemplate: JSON.stringify(formData.dayTemplate),
          events: JSON.stringify(purgeEvents()),
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          setFormData({
            ...formData,
            dayTemplate: JSON.parse(response.data.dayTemplate),
            events: JSON.parse(response.data.events),
          });
          showDialog(
            "Day Template created/updated successfully!",
            "Day Template"
          );
        }
      });
  };

  const swapUtilizedStudent = (studentOld, studentNew) => {
    const buffer = [...utilizedStudents];

    buffer.splice(utilizedStudents.indexOf(studentOld), 1);

    setSelectedStudent(studentNew);
    addUtilizedStudent(buffer);
  };

  const updateEvents = (uEvents) => {
    const events = [...(uEvents || formData.events)].sort((a, b) => {
      const startA = new Date(`2000-01-01 ${a.start}`).getTime();
      const startB = new Date(`2000-01-01 ${b.start}`).getTime();

      if (startA < startB) return -1;
      else if (startA > startB) return 1;
      else return 0;
    });

    setFormData({ ...formData, events: [...events] });
  };

  const updateStudentData = (uStudents, uUtilizedStudents) => {
    const buffer = [];
    uStudents.forEach((student) => {
      if (uUtilizedStudents.indexOf(student) < 0) buffer.push(student);
    });

    setUtilizedStudents(uUtilizedStudents);
    setAvailableStudents(buffer);

    if (buffer.length) setSelectedStudent(buffer[0]);

    return buffer.length ? buffer[0] : null;
  };

  const updateTitle = (e) => {
    formData.dayTemplate.title = e.target.value;
    setFormData({ ...formData });
  };

  const _getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourschoolhub.online/dayTemplate/id/${props.userId}/dayTemplateId/${props.id}`
      )
      .then((response) => {
        const responseDayTemplate = JSON.parse(response.data.dayTemplate);
        const responseStudents = JSON.parse(response.data.students);
        const responseEvents = JSON.parse(response.data.events);
        const responseRoutines = JSON.parse(response.data.routines);
        const responseStudentIds = responseStudents.map(
          (student) => student.id
        );

        setStudents(responseStudents);
        updateStudentData(responseStudents, [
          ...new Set(
            responseEvents.map(
              (event) =>
                responseStudents[responseStudentIds.indexOf(event.fk_studentId)]
            )
          ),
        ]);

        setRoutines(responseRoutines);

        setFormData({
          userId: props.userId,
          dayTemplate: {
            ...{ id: null, title: null },
            ...responseDayTemplate,
          },
          events: responseEvents,
        });
      });
  }, [props.id, props.userId]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData || !students) return null;

  return (
    <div className="calendar">
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <DayTemplateEventDetails
        show={!!activeEvent}
        events={formData.events}
        event={activeEvent}
        index={formData.events.indexOf(activeEvent)}
        updateEvents={updateEvents}
        onSave={submit}
        onClose={() => setActiveEvent(null)}
      />

      <Row className="mt-3">
        <Col>
          <ReturnLink href="/mydaytemplates" label="Day Templates" />
        </Col>
      </Row>

      {!(students || []).length && (
        <Alert variant="danger" className="mt-3">
          <a href="/mystudents" className="text-decoration-none text-danger">
            <ExclamationCircleFill /> You currently have no students. Click here
            to add students.
          </a>
        </Alert>
      )}

      {!!(students || []).length && !(utilizedStudents || []).length && (
        <Alert variant="info" className="mt-3">
          <ExclamationCircleFill /> You currently haven't added any students to
          the day. To do so, select a student from the dropdown below and then
          press "Add Student".
        </Alert>
      )}

      {!!(students || []).length &&
        !!(utilizedStudents || []).length &&
        !(formData.events || []).length && (
          <Alert variant="info" className="mt-3">
            <ExclamationCircleFill /> You currently haven't added any tasks to
            the day. To do so:
            <ol className="mt-3">
              <li>click on the appropriate hour within a student's timeline</li>
              <li>
                click "Apply Routine" within a student's timeline to apply any
                routines you've already created
              </li>
            </ol>
          </Alert>
        )}

      <Row className="mt-3">
        <Col>
          <FloatingLabel label="Title">
            <Form.Control
              placeholder="Title"
              value={formData.dayTemplate.title || ""}
              onChange={updateTitle}
            />
          </FloatingLabel>
        </Col>
      </Row>

      {utilizedStudents.map((student, index) => (
        <DayTemplateAgenda
          key={`studentAgenda${index}`}
          student={student}
          students={students}
          events={formData.events}
          availableStudents={availableStudents}
          utilizedStudents={utilizedStudents}
          scroll={scroll}
          onScroll={(ref) =>
            setScroll({ ref: ref, value: ref.current.scrollLeft })
          }
          addUtilizedStudent={addUtilizedStudent}
          swapUtilizedStudent={swapUtilizedStudent}
          addEvent={addEvent}
          updateEvents={updateEvents}
          setActiveEvent={setActiveEvent}
          removeEvent={removeEvent}
          routines={routines}
        />
      ))}

      <Row className="mt-3">
        <Col md={8}></Col>
        <Col>
          <InputGroup>
            <Form.Select
              disabled={!availableStudents.length}
              onChange={(e) =>
                setSelectedStudent(students[parseInt(e.target.value)])
              }
            >
              {students.map((student, index) =>
                utilizedStudents.indexOf(student) >= 0 ? null : (
                  <option key={`studentOption${index}`} value={index}>
                    {student.alias || student.login}
                  </option>
                )
              )}
            </Form.Select>
            <Button
              disabled={!availableStudents.length}
              variant="outline-secondary"
              onClick={() => addUtilizedStudent()}
            >
              <PlusCircle></PlusCircle>&nbsp;Add Student
            </Button>

            <Button variant="primary" onClick={() => submit()}>
              <Check2Circle></Check2Circle>&nbsp;Save
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

export default DayTemplateEdit;
