import React from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  Ratio,
  Row,
} from "react-bootstrap";
import {
  ArrowRepeat,
  Calendar,
  CardList,
  ExclamationCircleFill,
  GraphUpArrow,
  HandThumbsUp,
  People,
  PlayCircle,
  Trophy,
} from "react-bootstrap-icons";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { toBoolean } from "../../Shared/Util";

function Guide(props) {
  const letsMoveOn = (field) => {
    const buffer = {
      guideStudentsComplete: props.cookies.guideStudentsComplete,
      guideCalendarComplete: props.cookies.guideCalendarComplete,
      guideRoutinesComplete: props.cookies.guideRoutinesComplete,
      guideDayTemplatesComplete: props.cookies.guideDayTemplatesComplete,
      guidePrizesComplete: props.cookies.guidePrizesComplete,
      guideAnalyticsComplete: props.cookies.guideAnalyticsComplete,
      guideTutorialsComplete: props.cookies.guideTutorialsComplete,
    };

    props.setCookie(field, true, {
      maxAge: 90 * 24 * 60 * 60,
    });

    buffer[field] = true;

    props.setIndex(
      !toBoolean(buffer.guideStudentsComplete)
        ? "0"
        : !toBoolean(buffer.guideCalendarComplete)
        ? "1"
        : !toBoolean(buffer.guideRoutinesComplete)
        ? "2"
        : !toBoolean(buffer.guideDayTemplatesComplete)
        ? "3"
        : !toBoolean(buffer.guidePrizesComplete)
        ? "4"
        : !toBoolean(buffer.guideAnalyticsComplete)
        ? "5"
        : !toBoolean(buffer.guideTutorialsComplete)
        ? "6"
        : null
    );

    props.setShow(
      !toBoolean(buffer.guideStudentsComplete) ||
        !toBoolean(buffer.guideCalendarComplete) ||
        !toBoolean(buffer.guideRoutinesComplete) ||
        !toBoolean(buffer.guideDayTemplatesComplete) ||
        !toBoolean(buffer.guidePrizesComplete) ||
        !toBoolean(buffer.guideAnalyticsComplete) ||
        !toBoolean(buffer.guideTutorialsComplete)
    );
  };

  const openPage = (page) => (window.location = page);

  return (
    <Modal
      centered
      size="lg"
      show={props.show}
      onHide={() => props.setShow(false)}
    >
      <Modal.Header
        className="bg-dark text-light h5"
        closeButton
        closeVariant="white"
      >
        <ExclamationCircleFill />
        &nbsp;Welcome to Our School Hub!
      </Modal.Header>
      <ModalBody className="text-left">
        <div className="display-6 mb-3">
          Excited to get going but not sure what to do? Here's a guide to get
          you started.
        </div>

        <Accordion flush activeKey={props.index || "0"}>
          <AccordionItem eventKey="0">
            <Accordion.Header>
              <People />
              &nbsp;List Students
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  To begin, you'll need to list your students. During which
                  you'll provide them with logins, passwords, and (optionally)
                  aliases.
                  {props.path &&
                    props.path[1] &&
                    props.path[1] === "mystudents" && (
                      <p className="mt-3">
                        <strong>
                          Hurray! You are on the correct page! Please, close
                          this popup to start listing your students.
                        </strong>
                      </p>
                    )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Students Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109ynk/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideStudentsComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/mystudents")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="1">
            <Accordion.Header>
              <Calendar />
              &nbsp;Fill Your Calendar
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  Let's learn how to simply add a task to our calendars!
                  {props.path &&
                    props.path[1] &&
                    props.path[1] === "mycalendar" && (
                      <p className="mt-3">
                        <strong>
                          Hurray! You are on the correct page! Please, close
                          this popup to begin filling your calendar.
                        </strong>
                      </p>
                    )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Calendar Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109wfx/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideCalendarComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/mycalendar")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="2">
            <Accordion.Header>
              <ArrowRepeat />
              &nbsp;Create Routines
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  Do one or more of your students have recurring agendas? Save
                  time and effort with routines!
                  {props.path &&
                    props.path[1] &&
                    props.path[1] === "myroutines" && (
                      <p className="mt-3">
                        <strong>
                          Hurray! You are on the correct page! Please, close
                          this popup to being creating routines.
                        </strong>
                      </p>
                    )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Routines Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109ybp/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideRoutinesComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/myroutines")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="3">
            <Accordion.Header>
              <CardList />
              &nbsp;Create Day Templates
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  If any of your students follow the same schedule one or more
                  days of the week, simplify things with Day Templates!
                  {props.path &&
                    props.path[1] &&
                    props.path[1] === "mydaytemplates" && (
                      <p className="mt-3">
                        <strong>
                          Hurray! You are on the correct page! Please, close
                          this popup to begin create day templates.
                        </strong>
                      </p>
                    )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Day Templates Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109vzt/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideDayTemplatesComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/mydaytemplates")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="4">
            <Accordion.Header>
              <Trophy />
              &nbsp;Setup Prizes
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  Everyone can benefit from incentives. Provide your students
                  with some inspiration! Create prizes that your students can
                  earn with points they collect while completing tasks!
                  {props.path && props.path[1] && props.path[1] === "myprizes" && (
                    <p className="mt-3">
                      <strong>
                        Hurray! You are on the correct page! Please, close this
                        popup to begin creating prizes.
                      </strong>
                    </p>
                  )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Prizes Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109x0e/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guidePrizesComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/myprizes")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="5">
            <Accordion.Header>
              <GraphUpArrow />
              &nbsp;Analytics
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  Monitor your students' progress. You can view how many points
                  they've garnered per date, how much time they've spent on each
                  subject, and more!
                  {props.path &&
                    props.path[1] &&
                    props.path[1] === "analytics" && (
                      <p className="mt-3">
                        <strong>
                          Hurray! You are on the correct page! Please, close
                          this popup to begin creating prizes.
                        </strong>
                      </p>
                    )}
                  <Ratio aspectRatio="21x9" className="mt-3">
                    <iframe
                      title="Analytics Tutorial"
                      class="rumble"
                      src="https://rumble.com/embed/v109vib/?pub=107jef"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Ratio>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideAnalyticsComplete")}
                    >
                      Let's move on
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openPage("/analytics")}
                    >
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>

          <AccordionItem eventKey="6">
            <Accordion.Header>
              <PlayCircle />
              &nbsp;View Tutorials
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  Still have questions? Things not quite clear yet? Try our
                  video tutorials!
                  {props.path && props.path[1] && props.path[1] === "help" && (
                    <p className="mt-3">
                      <strong>
                        Hurray! You are on the correct page! Please, close this
                        popup to begin viewing tutorials.
                      </strong>
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-end">
                  <ButtonGroup>
                    <Button
                      variant="light"
                      onClick={() => letsMoveOn("guideTutorialsComplete")}
                    >
                      <HandThumbsUp /> Done!
                    </Button>
                    <Button variant="primary" onClick={() => openPage("/help")}>
                      Let's do it!
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Accordion.Body>
          </AccordionItem>
        </Accordion>
      </ModalBody>
    </Modal>
  );
}

export default Guide;
