import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PageIntro from "./Shared/PageInto";

function Help(props) {
  const rumbleVideo = (id, title) => (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="ratio ratio-16x9">
          <iframe
            title={title}
            className="rumble"
            width="100%"
            height="100%"
            src={`https://rumble.com/embed/${id}/?pub=107jef`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <PageIntro noGuide>
        Need help? Have questions, comments, or suggestions? Perhaps the video
        tutorials below can help. Or click{" "}
        <a
          className="m3-3 text-decoration-none"
          href="https://www.facebook.com/groups/ourschoolhub"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>{" "}
        to reach us via our Facebook group.
      </PageIntro>

      <div className="mt-5">
        <div className="display-4">Overview</div>
        <PageIntro noGuide={true}>
          Interested in Our School Hub but not certain how it can benefit you?
          View these videos to see how Our School Hub can simplify
          homeschooling.
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vzw9dv", "Quick Into to Our School Hub")}</Col>
          <Col>{rumbleVideo("v1001bh", "Tour of Our School Hub")}</Col>
          <Col>
            {rumbleVideo("v106q39", "In-Depth Tutorial of Our School Hub")}
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <div className="display-4">Students</div>
        <PageIntro noGuide={true}>
          To begin, you'll need to list your students. During which you'll
          provide them with logins, passwords, and (optionally) aliases. When
          available, students will be identified throughout the site by their
          alias. As opposed to their logins.
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vz3mob", "Create and remove students")}</Col>
          <Col>{rumbleVideo("vz3mm3", "Mark a task completed")}</Col>
          <Col>{rumbleVideo("vz3mi7", "Claim a prize")}</Col>
        </Row>
      </div>

      <div className="mt-5">
        <div className="display-4">Calendar</div>
        <PageIntro noGuide={true}>
          It's time to fill your calendar! You can customize each day manually,
          or simplify the process using the <a href="/myroutines">routines</a>{" "}
          and <a href="/mydaytemplates">day&nbsp;templates</a> you created. As
          soon as you setup a day in your calendar, your students can login and
          view their tasks!
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vz3lr9", "Add a task to the calendar")}</Col>
          <Col>
            {rumbleVideo("vz3lwj", "Apply a day template to the calendar")}
          </Col>
          <Col>{rumbleVideo("vz3m19", "Apply a routine to the calendar")}</Col>
        </Row>
      </div>

      <div className="mt-3">
        <div className="display-4">Routines</div>
        <PageIntro noGuide={true}>
          If you find that one or more of your students have recurring, daily
          agendas, you may want to create a routine. Routines are schedules
          independent of specific students and dates. Meaning, you don't
          initially assign them to a student or date. Later (when creating{" "}
          <a href="/mydaytemplates">day templates</a> or filling your{" "}
          <a href="/mycalendar">calendar</a>) you'll be able to assign them to
          students and (regarding your calendar) dates.
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vz3mff", "Create a routine")}</Col>

          <Col>{rumbleVideo("vz3m19", "Apply a routine to the calendar")}</Col>
          <Col>
            {rumbleVideo("vz3m8r", "Applay a routine to a day template")}
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <div className="display-4">Day Templates</div>
        <PageIntro noGuide={true}>
          Often your students will repeat the same routines on certain days of
          the week. Day templates are where you can start pairing the{" "}
          <a href="/myroutines">routines</a> you've created with your students:
          effectively setting up their recurring tasks for a day. At this point,
          you don't need to worry about dates. When filling out your{" "}
          <a href="/mycalendar">calendar</a> you'll be able to apply your day
          templates to specific days.
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vz3m55", "Create a day template")}</Col>
          <Col>
            {rumbleVideo("vz3m8r", "Applay a routine to a day template")}
          </Col>
          <Col>
            {rumbleVideo("vz3lwj", "Apply a day template to the calendar")}
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <div className="display-4">Prizes</div>
        <PageIntro noGuide={true}>
          Everyone can benefit from incentives. Provide your students with some
          inspiration! Create prizes that your students can earn. Then, as you
          create your <a href="/myroutines">routines</a>,{" "}
          <a href="/mydaytemplates">day templates</a> and fill out your{" "}
          <a href="/mycalendar">calendar</a>, assign points to tasks. Your
          students will be able to accumulate points as they complete tasks.
          Afterwards, they can use their points to claim prizes!
        </PageIntro>
        <Row>
          <Col>{rumbleVideo("vz3map", "Create a prize")}</Col>
          <Col>{rumbleVideo("vz3mi7", "Claim a prize")}</Col>
          <Col>{rumbleVideo("vz3mcn", "Mark a prize fulfilled")}</Col>
        </Row>
      </div>
    </>
  );
}

export default Help;
