import React from "react";
import { Button } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { useCookies } from "react-cookie";
import { toBoolean } from "../Shared/Util";

function Footer(props) {
  const [cookies, setCookie] = useCookies(["acceptedCookies"]);

  const iUnderstand = () => {
    setCookie("acceptedCookies", true, {
      maxAge: 90 * 24 * 60 * 60,
    });
  };

  return (
    <>
      <div className="text-center my-3">
        &copy;{new Date().getFullYear()}
        &nbsp;OurSchoolHub.online
        <br />
        <a href="/userAgreement" target="_blank">
          User Agreement
        </a>
        &nbsp;/&nbsp;
        <a href="/privacyPolicy" target="_blank">
          Privacy Policy
        </a>
      </div>
      {!toBoolean(cookies.acceptedCookies) && (
        <div className="bg-light text-dark p-3 text-center fixed-bottom">
          Our School Hub uses cookies.{" "}
          <a href="/userAgreement" target="_blank">
            User Agreement
          </a>
          &nbsp;/&nbsp;
          <a href="/privacyPolicy" target="_blank">
            Privacy Policy
          </a>{" "}
          <Button onClick={() => iUnderstand()}>
            <Check2Circle /> I understand
          </Button>
        </div>
      )}
    </>
  );
}

export default Footer;
