import { useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { ChevronLeft, ChevronRight, SlashLg } from "react-bootstrap-icons";
import padZeros, { stringToDate } from "../Shared/Util";
import moment from "moment";

const InputDate = (props) => {
  const propsDate = props?.date ? stringToDate(props.date) : new Date();

  const [selectedDay, setSelectedDay] = useState(propsDate.getDate());
  const [selectedMonth, setSelectedMonth] = useState(propsDate.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(propsDate.getFullYear());

  const days = [];
  for (var d = 1; d <= new Date(selectedYear, selectedMonth, 0).getDate(); d++)
    days.push(d);

  const years = [];
  for (
    var y = parseInt(selectedYear) - 100;
    y <= parseInt(selectedYear) + 100;
    y++
  )
    years.push(y);

  const adjustDay = (month, year) => {
    const maxDays = new Date(year, month, 0).getDate();
    const day = selectedDay < maxDays ? selectedDay : maxDays;
    setSelectedDay(day);

    return day;
  };

  const execOnChange = (day, month, year) => {
    day = `00${day}`;
    month = `00${month}`;
    year = `0000${year}`;

    props.onChange(
      `${year.substring(year.length - 4)}-${month.substring(
        month.length - 2
      )}-${day.substring(day.length - 2)}`
    );
  };

  const left = () => {
    const date = `${padZeros(selectedYear, 4)}-${padZeros(
      selectedMonth,
      2
    )}-${padZeros(selectedDay, 2)}`;

    const newDate = !props.hideDay
      ? moment(date).subtract(1, "days")
      : moment(date).subtract(1, "months");

    const newDay = parseInt(newDate.format("DD"));
    const newMonth = parseInt(newDate.format("MM"));
    const newYear = parseInt(newDate.format("YYYY"));

    setSelectedDay(newDay);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    props.onChange(
      `${padZeros(newYear, 4)}-${padZeros(newMonth, 2)}-${padZeros(newDay, 2)}`
    );
  };

  const right = () => {
    const date = `${padZeros(selectedYear, 4)}-${padZeros(
      selectedMonth,
      2
    )}-${padZeros(selectedDay, 2)}`;

    const newDate = !props.hideDay
      ? moment(date).add(1, "days")
      : moment(date).add(1, "months");

    const newDay = parseInt(newDate.format("DD"));
    const newMonth = parseInt(newDate.format("MM"));
    const newYear = parseInt(newDate.format("YYYY"));

    setSelectedDay(newDay);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    props.onChange(
      `${padZeros(newYear, 4)}-${padZeros(newMonth, 2)}-${padZeros(newDay, 2)}`
    );
  };

  const updateDay = (day) => {
    setSelectedDay(day);
    if (props.onChange) execOnChange(day, selectedMonth, selectedYear);
  };

  const updateMonth = (month) => {
    const day = adjustDay(month, selectedYear);
    setSelectedMonth(month);
    if (props.onChange) execOnChange(day, month, selectedYear);
  };

  const updateYear = (year) => {
    const day = adjustDay(selectedMonth, year);
    setSelectedYear(year);
    if (props.onChange) execOnChange(day, selectedMonth, year);
  };

  return (
    <div className={props.className}>
      <Card>
        {props.label && (
          <small className="text-secondary ps-2 pt-2">{props.label}</small>
        )}
        <Card.Body className="p-0">
          <InputGroup>
            <Button variant="light" className="p-0" onClick={() => left()}>
              <ChevronLeft />
            </Button>
            <Form.Select
              className="border-0 p-0 ps-2"
              value={selectedMonth}
              onChange={(e) => updateMonth(e.target.value)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => (
                <option key={`month-${index}`} value={month}>
                  {new Date(selectedYear, month - 1, 1).toLocaleString(
                    "default",
                    {
                      month: "long",
                    }
                  )}
                </option>
              ))}
            </Form.Select>
            {!props.hideDay && (
              <>
                &nbsp;
                <SlashLg className="mt-1" />
                &nbsp;
                <Form.Select
                  className="border-0 p-0 ps-2"
                  value={selectedDay}
                  onChange={(e) => updateDay(e.target.value)}
                >
                  {days.map((day, index) => (
                    <option key={`day-${index}`} value={day}>
                      {day}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
            &nbsp;
            <SlashLg className="mt-1" />
            &nbsp;
            <Form.Select
              className="border-0 p-0 ps-2"
              value={selectedYear}
              onChange={(e) => updateYear(e.target.value)}
            >
              {years.map((year, index) => (
                <option key={`year-${index}`} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
            <Button variant="light" className="p-0" onClick={() => right()}>
              <ChevronRight />
            </Button>
          </InputGroup>
        </Card.Body>
      </Card>
      {props.label && <Form.Text>{props.instructions}</Form.Text>}
    </div>
  );
};

export default InputDate;
