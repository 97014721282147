import moment from "moment";
import { Col, Row } from "react-bootstrap";
import ProgressNote from "../../Shared/ProgressNote";
import { timeToDate, toBoolean } from "../../Shared/Util";

const CalendarAgendaAnalytics = (props) => {
  const events = props.events.filter((event) =>
    event.fk_studentId === props.studentId ? event : null
  );

  const completed = events.filter((event) =>
    toBoolean(event.completed) ? event : null
  );

  return (
    <Row>
      <Col xs={12} md={3}>
        <ProgressNote
          label="Tasks"
          suffix="completed"
          milestone={completed.length}
          goal={events.length}
        />
      </Col>
      <Col xs={12} md={3}>
        <ProgressNote
          label="Time"
          suffix="completed"
          isMinutes
          milestone={completed
            .map((event) =>
              moment(timeToDate(event.end)).diff(
                timeToDate(event.start),
                "minutes",
                true
              )
            )
            .reduce((a, b) => a + b, 0)}
          goal={events
            .map((event) =>
              moment(timeToDate(event.end)).diff(
                timeToDate(event.start),
                "minutes",
                true
              )
            )
            .reduce((a, b) => a + b, 0)}
        />
      </Col>
      <Col xs={12} md={3}>
        <ProgressNote
          label="Points"
          suffix="earned"
          milestone={completed
            .map(
              (event) =>
                parseFloat(event.pointsCompletion) +
                parseFloat(
                  parseFloat(event.percentage) >= 100
                    ? event.points100
                    : parseFloat(event.percentage) >= 90
                    ? event.points90
                    : parseFloat(event.percentage) >= 80
                    ? event.points80
                    : parseFloat(event.percentage) >= 70
                    ? event.points70
                    : 0
                )
            )
            .reduce((a, b) => a + b, 0)}
          goal={events
            .map(
              (event) =>
                parseFloat(event.pointsCompletion) +
                Math.max(
                  parseFloat(event.points70 || ""),
                  parseFloat(event.points80 || ""),
                  parseFloat(event.points90 || ""),
                  parseFloat(event.points100 || "")
                )
            )
            .reduce((a, b) => a + b, 0)}
        />
      </Col>
    </Row>
  );
};

export default CalendarAgendaAnalytics;
