import React from "react";
import { useCookies } from "react-cookie";
import { Card, Image, Tab, Tabs } from "react-bootstrap";
import Login from "./Login";
import Signup from "./Signup";
import padZeros, { toBoolean } from "../Shared/Util";

function LoginSingup(props) {
  const [cookies] = useCookies(["user_id", "isStudent"]);

  if (cookies.user_id) {
    window.location = toBoolean(cookies.isStudent)
      ? "/myday"
      : `/mycalendar/year/${padZeros(
          new Date().getFullYear(),
          4
        )}/month/${padZeros(new Date().getMonth() + 1, 2)}/day/${padZeros(
          new Date().getDate(),
          2
        )}`;
    return null;
  } else
    return (
      <div className="text-center">
        <Image className="image-fluid w-50 my-3" src="ourschoolhub.png"></Image>
        <Card className="mt-3 p-3">
          <Tabs
            defaultActiveKey="login"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="login" title="Login">
              <Login></Login>
            </Tab>
            <Tab eventKey="Signup" title="Signup">
              <Signup></Signup>
            </Tab>
          </Tabs>
        </Card>
      </div>
    );
}

export default LoginSingup;
