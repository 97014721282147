import ReactDOMServer from "react-dom/server";
import React, { useCallback, useState, useEffect } from "react";
import ReactParseHTML from "react-html-parser";
import axios from "axios";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import Dialog from "../../Shared/Dialog";
import { Editor } from "@tinymce/tinymce-react";
import { Check2Circle } from "react-bootstrap-icons";
import ReturnLink from "../../Shared/Return";
import { removeLineBreaks } from "../../Shared/Util";

function PrizeEdit(props) {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [formData, setFormData] = useState({
    id: null,
    fk_typeId: "c2718e81-b9c5-11ec-ab0f-002590aaf58e",
    title: null,
    points: null,
    description: null,
  });

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    if (formData.title.length < 1 || formData.title.length > 100) {
      showDialog("Please enter a title between 1 and 100 characters");
    } else if (!formData.points) {
      showDialog("Please enter cost in points");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourschoolhub.online/prizeEdit",
          qs.stringify({
            userId: props.userId,
            prize: JSON.stringify(formData),
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data?.error) showDialog(response.data?.error, "Prize");
          else {
            setFormData({
              ...{
                id: null,
                fk_typeId: "c2718e81-b9c5-11ec-ab0f-002590aaf58e",
                title: null,
                points: null,
                description: null,
              },
              ...JSON.parse(response.data),
            });
            showDialog("Prize created/updated successfully!", "Prize");
          }
        });
    }
  };

  const _getData = useCallback(async () => {
    await axios
      .get(`https://api.ourschoolhub.online/prize/id/${props.id}`)
      .then((response) =>
        setFormData({
          ...{
            id: null,
            fk_typeId: "c2718e81-b9c5-11ec-ab0f-002590aaf58e",
            title: null,
            points: null,
            description: null,
          },
          ...JSON.parse(response.data),
        })
      );
  }, [props.id]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!formData) return null;

  return (
    <Form>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Row className="mt-3">
        <Col>
          <ReturnLink href="/myprizes" label="Prizes" />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <FloatingLabel label="Type">
            <Form.Select
              placeholder="Type"
              value={
                formData.fk_typeId || "c2718e81-b9c5-11ec-ab0f-002590aaf58e"
              }
              onChange={(e) => {
                setFormData({ ...formData, fk_typeId: e.target.value });
              }}
            >
              <option value="c25dbf5c-b9c5-11ec-ab0f-002590aaf58e">
                Activity
              </option>
              <option value="c26b754f-b9c5-11ec-ab0f-002590aaf58e">
                Money
              </option>
              <option value="c2718e81-b9c5-11ec-ab0f-002590aaf58e">
                Other
              </option>
              <option value="c2655cc5-b9c5-11ec-ab0f-002590aaf58e">
                Treat
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col xs="12" md="6" className="">
          <FloatingLabel label="Title">
            <FormControl
              placeholder="Title"
              value={formData.title || ""}
              onChange={(e) => {
                setFormData({ ...formData, title: e.target.value });
              }}
            />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Cost in Points">
            <Form.Control
              type="number"
              placeholder="Cost in Points"
              value={formData.points || ""}
              onChange={(e) => {
                setFormData({ ...formData, points: e.target.value });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Editor
            tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
            value={ReactDOMServer.renderToString(
              ReactParseHTML(formData.description || "")
            )}
            onEditorChange={(newValue) => {
              setFormData({
                ...formData,
                description: removeLineBreaks(newValue),
              });
            }}
            init={{
              height: 250,
              branding: false,
              menubar: false,
              plugins: "link,lists",
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link | forecolor backcolor",
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={8}></Col>
        <Col className="text-end">
          <Button variant="primary" onClick={() => submit()}>
            <Check2Circle></Check2Circle>&nbsp;Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default PrizeEdit;
