import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CaretDownFill } from "react-bootstrap-icons";
import { agendaTime, dateToTimeString } from "../Shared/Util";
import CalendarEventBar from "../Admin/Calendar/CalendarEventBar";
import CalendarHour from "../Admin/Calendar/CalendarHour";
import TooltipOverlay from "../TooltipOverlay";

const StudenDayAgenda = (props) => {
  const ref = useRef();
  const [height, setHeight] = useState(4.5);
  const [timeOffset] = useState(agendaTime());

  const hours = [];
  for (var h = 0; h <= 23; h++) hours.push(h);

  useEffect(() => {
    if (props.focusEvent) {
      const minuteWidth = 300 / 60;

      const propsStart = props.focusEvent.start
        ? new Date(`2000-01-01 ${props.focusEvent.start}`)
        : new Date();

      const left =
        propsStart.getHours() * 300 + propsStart.getMinutes() * minuteWidth;

      ref.current.scrollLeft = left;
    }
  }, [props.focusEvent]);

  if (!ref) return null;

  return (
    <div className="calendar mt-3">
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <div className="day" ref={ref}>
                <div className="container" style={{ height: `${height}rem` }}>
                  <div className="timeline">
                    <TooltipOverlay tip={dateToTimeString(new Date())}>
                      <div
                        className="current"
                        style={{ left: `${timeOffset}px` }}
                      >
                        <CaretDownFill />
                      </div>
                    </TooltipOverlay>

                    {hours.map((hour, index) => (
                      <CalendarHour
                        key={`hour-${props?.student?.id}-${index}`}
                        studentId={props?.student?.id}
                        hour={hour}
                        readOnly={true}
                      />
                    ))}
                  </div>
                  {props.events.map((event, index) =>
                    event?.fk_studentId === props?.student?.id &&
                    !event?.deactivatedDateTime ? (
                      <CalendarEventBar
                        key={`calendarEvenBar-${event.fk_studentId}-${index}`}
                        events={props.events}
                        event={event}
                        index={index}
                        setActiveEvent={props.setActiveEvent}
                        setHeight={setHeight}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StudenDayAgenda;
