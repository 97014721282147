import React from "react";
import { useCookies } from "react-cookie";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import {
  ArrowRepeat,
  BoxArrowInLeft,
  BoxArrowRight,
  Calendar,
  CardList,
  Facebook,
  GraphUpArrow,
  People,
  QuestionCircleFill,
  Trophy,
} from "react-bootstrap-icons";
import StudentRaiseHand from "./Student/StudentRaiseHand";

function Menu() {
  const [cookies, , removeCookie] = useCookies(["user_id", "isStudent"]);

  const isStudent = cookies.isStudent === "true";

  const logout = () => {
    removeCookie("user_id", { path: "/" });
    removeCookie("isStudent", { path: "/" });
    window.location = "/login";
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Image src="/ourschoolhub.png" width="100" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/mystudents"
                  className={
                    ["/mystudents"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <People size="1.5em" />
                  &nbsp;Students
                </Nav.Link>
              )}
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/mycalendar"
                  className={
                    ["/mycalendar"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <Calendar size="1.5em" />
                  &nbsp;Calendar
                </Nav.Link>
              )}
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/myroutines"
                  className={
                    ["/myroutines"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <ArrowRepeat size="1.5em" />
                  &nbsp;Routines
                </Nav.Link>
              )}
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/mydaytemplates"
                  className={
                    ["/mydaytemplates"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <CardList size="1.5em" />
                  &nbsp;Day Templates
                </Nav.Link>
              )}
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/myprizes"
                  className={
                    ["/myprizes", "/prizeEdit/id/"].includes(
                      window.location.pathname
                    )
                      ? "text-white"
                      : ""
                  }
                >
                  <Trophy size="1.5em" />
                  &nbsp;Prizes
                </Nav.Link>
              )}
              {cookies.user_id && !isStudent && (
                <Nav.Link
                  href="/analytics"
                  className={
                    ["/analytics"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <GraphUpArrow size="1.5em" />
                  &nbsp;Analytics
                </Nav.Link>
              )}
              {cookies.user_id && isStudent && (
                <Nav.Link
                  href="/myday"
                  className={
                    ["/myday"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <Calendar size="1.5em" />
                  &nbsp;My Day
                </Nav.Link>
              )}
              {cookies.user_id && isStudent && (
                <Nav.Link
                  href="/prizes"
                  className={
                    ["/prizes"].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <Trophy size="1.5em" />
                  &nbsp;Prizes
                </Nav.Link>
              )}
              {!cookies.user_id && (
                <Nav.Link href="/login">
                  <BoxArrowInLeft size="1.5em" /> &nbsp;Login
                </Nav.Link>
              )}
              {cookies.user_id && (
                <Nav.Link
                  href=""
                  onClick={() => {
                    logout();
                  }}
                >
                  <BoxArrowRight size="1.5em" /> &nbsp;Logout
                </Nav.Link>
              )}
            </Nav>
            {!isStudent && (
              <Nav className="ms-auto">
                <Nav.Link href="/help" className="float-end">
                  <QuestionCircleFill color="white" size="2em" />
                </Nav.Link>
                <Nav.Link href="https://ourfight.online" target="_blank">
                  <Image src="/of.png" width="32" alt="" />
                </Nav.Link>
                <Nav.Link href="https://www.caucusroom.com/" target="_blank">
                  <Image
                    src="/CaucusRoom_ICON_FullColor.png"
                    width="32"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link
                  href="https://www.facebook.com/groups/ourschoolhub"
                  target="_blank"
                >
                  <Facebook color="white" size="2em" />
                </Nav.Link>
              </Nav>
            )}
            {cookies.user_id && isStudent && (
              <Nav className="ms-auto">
                <Nav.Item className="float-end">
                  <StudentRaiseHand userId={cookies.user_id} />
                </Nav.Item>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Menu;
