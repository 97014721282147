import { useState } from "react";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import { ArrowRepeat } from "react-bootstrap-icons";

const CalendarRoutineSelector = (props) => {
  const [removeCurrent, setRemoveCurrent] = useState(false);

  const applyRoutine = (routine) => {
    if (removeCurrent) {
      props.events.forEach((event, index) => {
        if (event.fk_studentId === props.student.id)
          props.removeEvent(event, index);
      });
    }

    const events = [...props.events];

    routine.events.map((event) =>
      events.push({
        ...event,
        id: null,
        fk_studentId: props.student.id,
        date: props.date,
      })
    );

    props.updateEvents(events);
    props.onClose();
  };

  return (
    <Modal centered size="md" show={props.show} onHide={props.onClose}>
      <Modal.Header
        className="bg-dark text-light h5"
        closeButton
        closeVariant="white"
      >
        <ArrowRepeat />
        &nbsp; Apply Routine
      </Modal.Header>
      <ModalBody className="text-left">
        <Form.Check
          type="switch"
          label="Remove current tasks"
          checked={removeCurrent}
          onChange={() => setRemoveCurrent(!removeCurrent)}
        />

        {props.routines.map((routine) => (
          <Button
            key={`routine${routine.id}`}
            variant="link"
            className="text-decoration-none w-100 text-start"
            onClick={() => applyRoutine(routine)}
          >
            {routine.title}
          </Button>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default CalendarRoutineSelector;
