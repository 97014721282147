import { useState } from "react";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { Check2Circle, Circle } from "react-bootstrap-icons";

const MultiSelect = (props) => {
  const [values, setValues] = useState([...(props.value || [])]);
  const [label, setLabel] = useState(
    props.children
      .map((child) =>
        values.indexOf(child.props.value) >= 0 ? child.props.children : null
      )
      .filter((item) => item)
      .join(", ")
  );

  const toggleValue = (value) => {
    let newValues = values;

    if (newValues.indexOf(value) >= 0) {
      newValues.splice(newValues.indexOf(value), 1);
      setValues([...newValues]);
    } else newValues = [...newValues, value];

    setValues(newValues);

    setLabel(
      props.children
        .map((child) =>
          newValues.indexOf(child.props.value) >= 0
            ? child.props.children
            : null
        )
        .filter((item) => item)
        .join(", ")
    );

    props.onChange(newValues);
  };

  return (
    <Card>
      {props.label && (
        <small className="text-secondary ps-2 pt-2">{props.label}</small>
      )}

      <Card.Body className="p-0">
        <DropdownButton
          className="multiSelect border-none"
          title={label}
          autoClose="outside"
        >
          {props.children.map((child, index) => (
            <Dropdown.Item
              key={`item-${child.props.value}-${index}`}
              onClick={() => toggleValue(child.props.value)}
            >
              {values.indexOf(child.props.value) >= 0 ? (
                <Check2Circle />
              ) : (
                <Circle />
              )}
              &nbsp;
              {child.props.children}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Card.Body>
    </Card>
  );
};

export default MultiSelect;
