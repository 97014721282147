import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { ArrowRepeat, CaretDownFill } from "react-bootstrap-icons";
import { agendaTime, dateToTimeString } from "../../Shared/Util";
import TooltipOverlay from "../../TooltipOverlay";
import CalendarAgendaAnalytics from "./CalendarAgendaAnalytics";
import CalendarEventBar from "./CalendarEventBar";
import CalendarHour from "./CalendarHour";
import CalendarRoutineSelector from "./CalendarRoutineSelector";

const CalendarAgenda = (props) => {
  const ref = useRef();
  const [height, setHeight] = useState(4.5);
  const [showRoutineSelector, setShowRoutineSelector] = useState(false);
  const [timeOffset] = useState(agendaTime());
  const [updateScroll, setUpdateScroll] = useState(false);
  const hours = [];
  for (var h = 0; h <= 23; h++) hours.push(h);

  const applyRoutine = () => {
    setShowRoutineSelector(true);
  };

  const updateStudent = (studentId) => {
    const studentsIds = props.students.map((student) => student.id);
    const utilizedStudentsIds = props.utilizedStudents.map(
      (student) => student.id
    );

    props.swapUtilizedStudent(
      props.utilizedStudents[utilizedStudentsIds.indexOf(props.student.id)],
      props.students[studentsIds.indexOf(studentId)]
    );
  };

  useEffect(() => {
    if (!props.scroll.ref || props.scroll.ref !== ref)
      ref.current.scrollLeft = props.scroll.value;
  }, [props.scroll]);

  if (!ref) return null;

  return (
    <div className="calendar mt-3">
      <CalendarRoutineSelector
        show={showRoutineSelector}
        onClose={() => setShowRoutineSelector(false)}
        date={props.date}
        student={props.student}
        events={props.events}
        routines={props.routines}
        updateEvents={props.updateEvents}
        removeEvent={props.removeEvent}
      />

      <Card className="border-none">
        <Card.Body>
          <Row>
            <Col md={3}>
              {!!props.availableStudents.length && (
                <Form.Select
                  className="border-0 studentName"
                  value={props.student.id}
                  onChange={(e) => updateStudent(e.target.value)}
                >
                  {props.students.map((student, index) =>
                    student !== props.student &&
                    props.utilizedStudents.indexOf(student) >= 0 ? null : (
                      <option key={`studentOption${index}`} value={student.id}>
                        {student.alias || student.login}
                      </option>
                    )
                  )}
                </Form.Select>
              )}
              {!props.availableStudents.length && (
                <div className="p-2 studentName">
                  {props.student.alias || props.student.login}
                </div>
              )}
            </Col>
            {!!props.routines?.length && (
              <Col className="text-end">
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={() => applyRoutine()}
                >
                  <ArrowRepeat size="1.5em" />
                  &nbsp;Apply Routine
                </Button>
              </Col>
            )}
          </Row>

          <Row className="mt-3">
            <Col>
              <CalendarAgendaAnalytics
                studentId={props?.student?.id}
                events={props.events}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <div
                className="day"
                onMouseLeave={() => setUpdateScroll(false)}
                onMouseEnter={() => setUpdateScroll(true)}
                onTouchEnd={() => setUpdateScroll(false)}
                onTouchStart={() => setUpdateScroll(true)}
                onScroll={() => {
                  if (updateScroll) props.onScroll(ref);
                }}
                ref={ref}
              >
                <div className="container" style={{ height: `${height}rem` }}>
                  <div className="timeline">
                    <TooltipOverlay tip={dateToTimeString(new Date())}>
                      <div
                        className="current"
                        style={{ left: `${timeOffset}px` }}
                      >
                        <CaretDownFill />
                      </div>
                    </TooltipOverlay>

                    {hours.map((hour, index) => (
                      <CalendarHour
                        key={`hour-${props?.student?.id}-${index}`}
                        studentId={props?.student?.id}
                        hour={hour}
                        addEvent={props.addEvent}
                      />
                    ))}
                  </div>
                  {props.events.map((event, index) =>
                    event?.fk_studentId === props?.student?.id &&
                    !event?.deactivatedDateTime ? (
                      <CalendarEventBar
                        key={`calendarEvenBar-${event.fk_studentId}-${index}`}
                        events={props.events}
                        event={event}
                        index={index}
                        removeEvent={props.removeEvent}
                        setActiveEvent={props.setActiveEvent}
                        setHeight={setHeight}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CalendarAgenda;
