import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Pen, PinAngleFill, XCircleFill } from "react-bootstrap-icons";
import axios from "axios";
import Dialog from "../../Shared/Dialog";
import { Editor } from "@tinymce/tinymce-react";
import ReactDOMServer from "react-dom/server";
import ReactParseHTML from "react-html-parser";
import Confirm from "../../Shared/Confirm";
import { removeLineBreaks, toBoolean } from "../../Shared/Util";
import TooltipOverlay from "../../TooltipOverlay";

const Note = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [note, setNote] = useState(null);
  const [remove, setRemove] = useState(null);

  const addNote = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Notes"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/noteAdd",
        qs.stringify({
          userId: props.userId,
          date: props.date,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          props.setNotes(JSON.parse(response.data.notes));
          hideDialog();
        }
      });
  };

  const noteDeactivate = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Notes"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/noteDeactivate",
        qs.stringify({
          userId: props.userId,
          date: props.date,
          id: remove.id,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          props.setNotes(JSON.parse(response.data.notes));
          hideDialog();
          setRemove(null);
        }
      });
  };

  const notePin = async (e, note) => {
    e.stopPropagation();

    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Notes"
    );

    const qs = require("qs");
    await axios
      .post(
        "https://api.ourschoolhub.online/notePin",
        qs.stringify({
          userId: props.userId,
          date: props.date,
          id: note.id,
          pinned: !toBoolean(note.pinned),
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          props.setNotes(JSON.parse(response.data.notes));
          hideDialog();
        }
      });
  };

  const noteUpdate = async () => {
    showDialog(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Saving...</span>
      </Spinner>,
      "Notes"
    );

    const qs = require("qs");

    await axios
      .post(
        "https://api.ourschoolhub.online/noteUpdate",
        qs.stringify({
          userId: props.userId,
          date: props.date,
          id: note.id,
          content: JSON.stringify(note.content),
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        if (response.data?.error) showDialog(response.data?.error);
        else {
          props.setNotes(JSON.parse(response.data.notes));
          hideDialog();
          setNote(null);
        }
      });
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const updateRemove = (e, note) => {
    e.stopPropagation();
    setRemove(note);
  };

  return (
    <>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      ></Dialog>

      <Confirm
        show={remove}
        header="Note"
        message={`Are you sure you want to remove this note?`}
        confirmText="Yes, Remove Note"
        onClose={() => setRemove(null)}
        onCancel={() => setRemove(null)}
        onConfirm={() => noteDeactivate()}
      />

      {!!note && (
        <Modal
          show
          size="xl"
          onHide={() => noteUpdate()}
          onEscapeKeyDown={() => noteUpdate()}
        >
          <Modal.Header closeButton>
            <Pen />
            &nbsp;Note
          </Modal.Header>
          <Modal.Body>
            <Editor
              tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
              value={ReactDOMServer.renderToString(
                ReactParseHTML(note?.content || "")
              )}
              onEditorChange={(newValue) => {
                setNote({ ...note, content: removeLineBreaks(newValue) });
              }}
              init={{
                height: 250,
                branding: false,
                menubar: false,
                plugins: "link,lists",
                toolbar:
                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link",
              }}
            />
          </Modal.Body>
        </Modal>
      )}

      <Button
        variant="link"
        className="text-decoration-none"
        onClick={() => addNote()}
      >
        <Pen /> Add Note
      </Button>

      {props.notes?.map((note) => (
        <div
          key={`note-${note.id}`}
          className="mb-3 note"
          onClick={() => setNote(note)}
        >
          <img alt="sticky" src="/sticky.svg" />

          <div className="noteContent">
            {ReactParseHTML(note.content || "Click to enter note")}
          </div>

          {!toBoolean(note.pinned) && (
            <TooltipOverlay tip="Pin note">
              <PinAngleFill
                className="notePin cursorPointer text-light"
                onClick={(e) => notePin(e, note)}
              />
            </TooltipOverlay>
          )}

          {toBoolean(note.pinned) && (
            <TooltipOverlay tip="Unpin note">
              <PinAngleFill
                className="notePin cursorPointer text-danger"
                onClick={(e) => notePin(e, note)}
              />
            </TooltipOverlay>
          )}

          <TooltipOverlay tip="Remove note">
            <XCircleFill
              className="noteRemove cursorPointer text-light"
              onClick={(e) => updateRemove(e, note)}
            />
          </TooltipOverlay>
        </div>
      ))}
    </>
  );
};

export default Note;
